import { lazy, useMemo } from "react";

import routerList from "./routerList";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/store";
import userRoleUtil from "../rbac/userRolesUtil";

import { Routes, Route, Navigate } from "react-router-dom";

import Carriers from "pages/Carriers";
import MarketplaceCarriers from "pages/MarketplaceCarriers";
import NotFound from "pages/NotFound/NotFound";

const MapPage = lazy(() => import("../pages/Map/Map"));
const Users = lazy(() => import("../pages/Users/Users"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const CarrierEdit = lazy(() => import("../pages/CarrierEdit"));
const UserEdit = lazy(() => import("../pages/UserEdit/UserEdit"));
const Customers = lazy(() => import("../pages/Customers/Customers"));
const Locations = lazy(() => import("../pages/Locations/Locations"));
const CustomerEdit = lazy(() => import("../pages/CustomerEdit/CustomerEdit"));
const CoveragePlans = lazy(
  () => import("../pages/CoveragePlans/CoveragePlans")
);
const LocationEdit = lazy(
  () => import("../pages/Locations/LocationEdit/LocationEdit")
);
const RoutingGuideContainer = lazy(
  () => import("../pages/RoutingGuide/RoutingGuideContainer")
);
const RoutingGuideDetails = lazy(
  () => import("../pages/RoutingGuide/RoutingGuideDetails")
);

const Router = () => {
  const user = useSelector((state: RootState) => state.oidc.user);
  const userRole = useMemo(
    () => userRoleUtil(user?.profile.roles ?? ""),
    [user]
  );

  return (
    <Routes>
      {/*Dashboard*/}
      <Route
        path="/"
        element={<Navigate to={routerList.dashboard} replace />}
      />
      <Route path={routerList.dashboard} element={<Dashboard />} />
      {/*Carrier*/}
      <Route
        path={routerList.carriers.list}
        element={<Carriers userRole={userRole} user={user} />}
      />
      <Route
        path={routerList.carriers.details}
        element={<CarrierEdit userRole={userRole} />}
      />
      {/*Customer*/}
      <Route
        path={routerList.customers.list}
        element={<Customers userRole={userRole} user={user} />}
      />
      <Route
        path={routerList.customers.details}
        element={<CustomerEdit userRole={userRole} />}
      />
      {/*Carrier Marketplace*/}
      <Route
        path={routerList.marketplaceCarriers}
        element={<MarketplaceCarriers />}
      />
      {/*Map*/}
      <Route path={routerList.map} element={<MapPage />} />
      {/*Routing Guide*/}
      <Route
        path={routerList.routingGuide.list}
        element={<RoutingGuideContainer />}
      />
      <Route
        path={routerList.routingGuide.details}
        element={<RoutingGuideDetails />}
      />
      {/*Location*/}
      <Route path={routerList.locations.list} element={<Locations />} />
      <Route path={routerList.locations.details} element={<LocationEdit />} />
      {/*Coverage Plans*/}
      <Route path={routerList.coveragePlans} element={<CoveragePlans />} />
      {/*Users*/}
      {userRole === "Administrator" && (
        <>
          <Route path={routerList.users.list} element={<Users />} />
          <Route path={routerList.users.details} element={<UserEdit />} />
        </>
      )}
      {/*Not Found*/}
      <Route path={routerList.notFound} element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
