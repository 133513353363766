import { useState } from "react";
import { CapacityWriteRow } from "./CapacityWriteRow";
import { CapacityReadRow } from "./CapacityReadRow";
import { CapacityLoaderRow } from "./CapacityLoaderRow";
import { useSelector } from "react-redux";

const READ_MODE = "READ";
const WRITE_MODE = "WRITE";

const CapacityRow = (props) => {
  const { item, tableActions, isEditing, selectedItemId, loadingRowId } = props;
  const {
    onRowUpdate,
    onRowDelete,
    onRowCreationCancel,
    onCapacityMatchesSelect,
    onCapacityExpire,
    onStartEdit,
    onFinishEdit,
  } = tableActions;

  const [mode, setMode] = useState(READ_MODE);
  const { options } = useSelector((state) => state.capacityMatching);

  const _handleEdit = () => {

    onStartEdit();
    setMode(WRITE_MODE);
  };

  const _handleCancel = () => {
    if (item.id === 0) {
      onRowCreationCancel();
    }
    onFinishEdit();
    setMode(READ_MODE);
  };

  const _handleUpdate = (data) => {
    onFinishEdit();
    setMode(READ_MODE);
    onRowUpdate(data);
  };

  if (loadingRowId === item.id) {
    return <CapacityLoaderRow />;
  }

  return mode === WRITE_MODE || item.id === 0 ? (
    <CapacityWriteRow
      item={item}
      equipmentTypeOptions={options.equipmentTypes}
      onSave={_handleUpdate}
      onCancel={_handleCancel}
    />
  ) : (
    <CapacityReadRow
      item={item}
      equipmentTypeOptions={options.equipmentTypes}
      active={selectedItemId === item.id}
      isEditing={isEditing}
      onEdit={_handleEdit}
      onDelete={onRowDelete}
      onExpire={onCapacityExpire}
      onSelect={onCapacityMatchesSelect}
    />
  );
};

export { CapacityRow };
