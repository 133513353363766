import { useRef, useState } from "react";
import { Table, Dimmer, Loader } from "semantic-ui-react";
import styled from "styled-components";

import { CapacityTableHeader } from "./CapacityTableHeader";
import { CapacityRow } from "./CapacityRow";
import { CapacityNoDataRow } from "./CapacityNoDataRow";

const CapacityTable = (props) => {
  const {
    data,
    loading,
    isEditing,
    mode,
    notesLoading,
    carrier,
    selectedItemId,
    loadingRowId,
    tableActions,
  } = props;
  const scrollerRef = useRef(null);
  const [shadow, setShadow] = useState(false);

  const handleTableScroll = () => {
    if (!scrollerRef?.current) {
      return;
    }

    if (!shadow && scrollerRef.current.scrollTop > 0) {
      setShadow(true);
    }

    if (shadow && scrollerRef.current.scrollTop === 0) {
      setShadow(false);
    }
  };

  return (
    <CapacityTableContainerStyled
      ref={scrollerRef}
      onScroll={() => {
        handleTableScroll();
      }}
    >
      {!loading && (
        <CapacityTableStyled striped selectable>
          <CapacityTableHeader
            shadow={shadow}
            carrier={carrier}
            isEditing={isEditing}
            mode={mode}
            notesLoading={notesLoading}
            onAddNew={tableActions.onAddNew}
            onNotesChange={tableActions.onNotesChange}
            onAddNewNoCapacity={tableActions.onAddNoCap}
            onClose={tableActions.onClose}
          />
          <Table.Body>
            {(!data || data.length === 0) && (
              <CapacityNoDataRow onAddNew={tableActions.onAddNew} />
            )}
            {data?.map((item, idx) => (
              <CapacityRow
                key={item.id}
                tabIndex={idx === 0 ? 0 : -1}
                item={item}
                idx={idx}
                isEditing={isEditing}
                selectedItemId={selectedItemId}
                loadingRowId={loadingRowId}
                tableActions={tableActions}
              />
            ))}
          </Table.Body>
        </CapacityTableStyled>
      )}
      <Dimmer active={loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    </CapacityTableContainerStyled>
  );
};

export { CapacityTable };

const CapacityTableContainerStyled = styled.div`
  flex-grow: 1;
  overflow: auto;
  background-color: #e8ebef;
  position: relative;
`;

const CapacityTableStyled = styled(Table)`
  border: 0 !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
`;
