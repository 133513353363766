import axios from "axios";

import { CarrierCoveragePlanCapacityDto } from "../../../../models/dto/CarrierCoveragePlanCapacityDto";

export const postCoveragePlanCapacity = ({
  carrierId,
  data,
}: {
  carrierId: number;
  data: CarrierCoveragePlanCapacityDto;
}): Promise<string> =>
  axios
    .post<string>(
      `/api/carriers/${carrierId}/knownlanes/coverageplancapacity`,
      data
    )
    .then((response) => response.data);

export const putCoveragePlanCapacity = ({
  carrierId,
  data,
}: {
  carrierId: number;
  data: CarrierCoveragePlanCapacityDto;
}): Promise<string> =>
  axios
    .put<string>(
      `/api/carriers/${carrierId}/knownlanes/coverageplancapacity`,
      data
    )
    .then((response) => response.data);
