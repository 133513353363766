import { useState, useEffect, SyntheticEvent } from "react";
import {
  Pagination as SemanticPagination,
  Dropdown,
  DropdownProps,
  PaginationProps
} from "semantic-ui-react";

import { formatNumberWithCommas } from "shared/utils/utility";

import styles from "./Pagination.module.scss";
import cx from "classnames";

interface PaginationComponentProps {
  onPageSizeChange: (pageSize: number) => void;
  onPageChange: (page: number) => void;
  noPadding?: boolean;
  halfPadding?: boolean;
  filteredCount?: number;
  componentSize?: string;
  pageSize?: number;
  page?: number;
}

const options = [
  { key: 10, value: 10, text: "10" },
  { key: 20, value: 20, text: "20" },
  { key: 50, value: 50, text: "50" },
  { key: 100, value: 100, text: "100" },
];

const Pagination = ({
  onPageSizeChange,
  onPageChange,
  noPadding,
  halfPadding,
  filteredCount = 0,
  componentSize = "small",
  pageSize = 10,
  page = 1,
}: PaginationComponentProps) => {
  const [totalPages, setTotalPages] = useState<number>(
    calculateTotalPages(filteredCount, pageSize),
  );

  const handlePageChange = (
    _: SyntheticEvent<HTMLElement>,
    { activePage }: PaginationProps
  ) => {
    if (typeof onPageChange === "function") {
      onPageChange(Number(activePage));
    }
  };

  const handlePageSizeChange = (
    _: SyntheticEvent<HTMLElement>,
    { value }: DropdownProps,
  ) => {
    if (typeof onPageSizeChange === "function") {
      onPageSizeChange(Number(value));
      setTotalPages(calculateTotalPages(filteredCount, Number(value)));
    }
  };

  useEffect(() => {
    setTotalPages(calculateTotalPages(filteredCount, pageSize));
  }, [filteredCount, pageSize]);

  return (
    <div
      className={cx(
        styles.pagination,
        halfPadding && styles.halfPadding,
        noPadding && styles.noPadding,
      )}
    >
      <span className={styles.total}>
        Total: {formatNumberWithCommas(filteredCount)}
      </span>
      <div>
        <Dropdown
          upward
          selection
          defaultValue={pageSize}
          options={options}
          onChange={handlePageSizeChange}
        />
        <SemanticPagination
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          size={componentSize}
          activePage={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

const calculateTotalPages = (filteredCount: number, pageSize: number): number =>
  Math.ceil(filteredCount / pageSize) || 1;

export default Pagination;
