//Map stats
export const FETCH_MAP_STATS_START = 'FETCH_MAP_STATS_START';
export const FETCH_MAP_STATS_SUCCESS = 'FETCH_MAP_STATS_SUCCESS';
export const FETCH_MAP_STATS_ERROR = 'FETCH_MAP_STATS_ERROR';

export const FETCH_REPS_STATS_START = 'FETCH_REPS_STATS_START';
export const FETCH_REPS_STATS_SUCCESS = 'FETCH_REPS_STATS_SUCCESS';
export const FETCH_REPS_STATS_ERROR = 'FETCH_REPS_STATS_ERROR';

export const FETCH_VEHICLES_STATS_START = 'FETCH_VEHICLES_STATS_START';
export const FETCH_VEHICLES_STATS_SUCCESS = 'FETCH_VEHICLES_STATS_SUCCESS';
export const FETCH_VEHICLES_STATS_ERROR = 'FETCH_VEHICLES_STATS_ERROR';

export const FETCH_MAP_OPTIONS_START = 'FETCH_MAP_OPTIONS_START';
export const FETCH_MAP_OPTIONS_SUCCESS = 'FETCH_MAP_OPTIONS_SUCCESS';
export const FETCH_MAP_OPTIONS_ERROR = 'FETCH_MAP_OPTIONS_ERROR';
