import Loader from 'components/Loader';
import MinifiedListRow from './MinifiedListRow';

import styles from '../CarrierList.module.scss';

const MinifiedListBody = ({
  carriersLoading,
  carrierData,
  onRowClick,
  onEditCarrier,
  selectedCarrier
}) => {
  if(carriersLoading) {
    return <Loader />;
  }

  if(!carrierData || carrierData.length === 0) {
    return <p className={styles.noData}>No data to display</p>;
  }

  return (
    carrierData.map(item => (
      <MinifiedListRow
        key={`mlr_${item.id}`}
        onRowClick={onRowClick}
        item={item}
        onEditCarrier={onEditCarrier}
        active={selectedCarrier?.id === item.id}
      />
      )
    )
  )
}

export default MinifiedListBody;