import React from "react";
import momentTz from "moment-timezone";
import { Icon, Label } from "semantic-ui-react";
import cx from "classnames";

import CarrierTriangleBadge from "../CarrierTriangleBadge";
import TableProfileImage from "components/TableProfileImage";
import CarrierStar from "components/CarrierStar";
import { MarketplaceCarrierIcon } from "components/MarketplaceIcon";
import { IS_EU } from "shared/constants/envConstants";
import { formatPhoneNumberWithExtentionAndCountryCode } from "shared/utils/utility";
import * as activityConstants from "shared/constants/activityConstants";

import styles from "../CarrierList.module.scss";

interface ListRowProps {
  item: any;
  onRowClick: (item: any) => void;
  onEditCarrier: (id: string) => void;
  user: { firstName: string; lastName: string };
}

const createContentSummary = (act: any) => {
  const summaryEntityType =
    activityConstants.entityTypes.find((type) => type.key === act.entityType)
      ?.text ?? "";

  const summaryAction =
    activityConstants.activityTypes.find((type) => type.key === act.actionType)
      ?.text ?? "";

  const user = act.userName ? `${act.userName}: ` : "";

  return `${user} ${summaryAction} ${summaryEntityType}`;
};

const ListRow = ({ item, onRowClick, onEditCarrier, user }: ListRowProps) => {
  const phoneTitle =
    item.phone && formatPhoneNumberWithExtentionAndCountryCode(item.phone);
  const phoneValue = item.phone
    ? formatPhoneNumberWithExtentionAndCountryCode(item.phone)
    : "Phone n/a";
  const lastActivityTitle = item.lastActivity
    ? createContentSummary(item.lastActivity)
    : null;
  const lastActivityVal = item.lastActivity
    ? createContentSummary(item.lastActivity)
    : "none";
  const notesTitle =
    item?.lastActivity?.notes?.length > 58 ? item.lastActivity.notes : null;
  const notesVal = item?.lastActivity?.notes || "n/a";

  const handleRowClick = (
    _: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: any,
  ) => {
    onRowClick(item);
  };

  const handleCarrierEdit = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onEditCarrier(item.id);
  };

  const renderAccountName = () => {
    if (item.owner) {
      return (
        <div className={styles.lastActivityData}>
          <p
            className={styles.lastActivityHeader}
            title={lastActivityTitle as string}
          >
            {item.name}
          </p>
          {item.owner && (
            <p
              className={cx(
                styles.lastActivityNotes,
                item.owner.name === `${user.firstName} ${user.lastName}`
                  ? styles.blue
                  : null,
              )}
              title={notesTitle}
            >
              {item.owner.name}
            </p>
          )}
        </div>
      );
    }

    return <p>{item.name}</p>;
  };

  return (
    <div
      id={`list_row_${item.id}`}
      className={styles.carrierRow}
      onClick={(e) => handleRowClick(e, item)}
    >
      <CarrierTriangleBadge
        key={`${item.id}_ctb`}
        turvoId={item.turvoId}
        statusId={item.statusCodeId}
      />

      <div>
        <CarrierStar
          key={`cstar_${item.id}`}
          starredByCurrentUser={item.isStarredByCurrentUser}
          starredByOthers={item.starredByUsers}
        />
        <TableProfileImage item={item} />
      </div>

      <div
        className={cx(styles.carrierName, styles.container)}
        title={item.name}
      >
        {renderAccountName()}
      </div>

      <div className={styles.numericData} title={item.mcNumber}>
        <p>{item.mcNumber}</p>
      </div>

      <div className={styles.numericData} title={item.dotNumber}>
        <p>{item.dotNumber}</p>
      </div>

      <div className={styles.phone} title={phoneTitle}>
        <p>{phoneValue}</p>
      </div>

      <div className={styles.additionalData} title={item.email || null}>
        <p style={{ maxWidth: "150px" }}>{item.email || "Email n/a"}</p>
      </div>

      <div className={styles.additionalData} title={item.address || null}>
        <p>{item.address || "n/a"}</p>
      </div>

      <div className={styles.lastActivityTime}>
        <p>
          {item.lastActivity?.createdAt
            ? momentTz
                .utc(item.lastActivity.createdAt)
                .tz("America/New_York")
                .format("MM/DD/YY h:mm A")
            : "never"}
        </p>
      </div>

      <div className={styles.lastActivityData}>
        <p
          className={styles.lastActivityHeader}
          title={lastActivityTitle as string}
        >
          {lastActivityVal}
        </p>
        <p className={styles.lastActivityNotes} title={notesTitle}>
          {notesVal}
        </p>
      </div>

      {!IS_EU && (
        <div className={styles.attributes}>
          <MarketplaceCarrierIcon profile={item} />
        </div>
      )}

      <div className={styles.matchesAndBids}>
        <p>
          {item.totalActiveMatches || 0}/{item.totalActiveCapacities || 0}
        </p>
      </div>

      <div className={styles.editIconContainer}>
        <Icon name="edit" link onClick={handleCarrierEdit} title="edit-icon" />
      </div>
      {item.unseenNotificationsCount > 0 && (
        <Label
          style={{ position: "absolute", right: "3px", top: "30px" }}
          circular
          color="blue"
          size="tiny"
        >
          {item.unseenNotificationsCount > 99
            ? "99+"
            : item.unseenNotificationsCount}
        </Label>
      )}
    </div>
  );
};

export default ListRow;
