import { useState } from "react";
import { Dimmer, Loader, Table } from "semantic-ui-react";

import CapacityCoveragePlanRow from "./CapacityCoveragePlanRow";
import { useGetCoveragePlanCapacities } from "pages/CoveragePlans/CoveragePlanForm/api/hooks/useGetCoveragePlanCapacities";
import { getDefaultCarrierCoveragePlanFormObject } from "pages/CoveragePlans/CarrierCoveragePlanForm/utils/carrierCoveragePlanDefaultFormObjects";
import CarrierCoveragePlanFormModal from "pages/CoveragePlans/CarrierCoveragePlanForm/CarrierCoveragePlanFormModal";
import Pagination from "components/_shared/Pagination";

import styles from "./CapacityCoveragePlans.module.scss";

type CapacityCoveragePlansProps = {
  carrierId: number;
  carrierName: string;
  isAddingPlanCapacity: boolean;
  closeAddingPlanCapacityModal: () => void;
};

const CapacityCoveragePlans = (props: CapacityCoveragePlansProps) => {
  const [filter, setFilter] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: 10,
  });

  const { data, isLoading, mutate } = useGetCoveragePlanCapacities({
    carrierId: props.carrierId,
    filter,
  });

  return (
    <>
      <div className={styles.capacityTableContainer}>
        <Table selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Plan Name</Table.HeaderCell>
              <Table.HeaderCell>Plan Lane</Table.HeaderCell>
              <Table.HeaderCell>Equipment</Table.HeaderCell>
              <Table.HeaderCell>Trucks</Table.HeaderCell>
              <Table.HeaderCell>Rate</Table.HeaderCell>
              <Table.HeaderCell>Note</Table.HeaderCell>
              <Table.HeaderCell>User Entered</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data?.entities.map((planCapacity) => (
              <CapacityCoveragePlanRow
                key={planCapacity.id}
                planCapacity={planCapacity}
                carrierId={props.carrierId}
                carrierName={props.carrierName}
                reloadCapacities={mutate}
              />
            ))}
          </Table.Body>
        </Table>

        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        {props.isAddingPlanCapacity ? (
          <CarrierCoveragePlanFormModal
            onClose={props.closeAddingPlanCapacityModal}
            defaultValues={getDefaultCarrierCoveragePlanFormObject()}
            carrierId={props.carrierId}
            carrierName={props.carrierName}
            reloadCapacities={mutate}
          />
        ) : null}
      </div>

      <Pagination
        page={filter.page}
        pageSize={filter.pageSize}
        filteredCount={data?.filteredCount ?? 0}
        onPageChange={(page: number) => setFilter({ ...filter, page })}
        onPageSizeChange={(pageSize: number) =>
          setFilter({ ...filter, page: 1, pageSize })
        }
        halfPadding
      />
    </>
  );
};

export default CapacityCoveragePlans;
