interface Activities {
  key: string,
  text: string
}

export const activityTypes: Activities[] = [
  { key: 'POST', text: 'Added' },
  { key: 'PUT', text: 'Updated' },
  { key: 'DELETE', text: 'Deleted' },
  { key: 'NOTE_CREATE', text: 'Note Added No Cap' },
  { key: 'LVM', text: 'Note added Left Voicemail' },
];

export const entityTypes: Activities[] = [
  { key: 'CARRIER', text: 'Carrier' },
  { key: 'CAPACITY', text: 'Capacity' },
  { key: 'KNOWN_LANE', text: 'Known Lane' },
  { key: 'USER', text: 'User' },
  { key: 'CUSTOMER', text: 'Customer' },
  { key: 'ADDRESS', text: 'Address' },
  { key: 'EMAIL', text: 'Email' },
  { key: 'PHONE', text: 'Phone' },
  { key: 'CONTACT', text: 'Contact' },
  { key: 'CONTACT_ADDRESS', text: 'Contact Address' },
  { key: 'CONTACT_EMAIL', text: 'Contact Email' },
  { key: 'CONTACT_PHONE', text: 'Contact Phone' },
  { key: 'EQUIPMENT', text: 'Equipment' },
  { key: 'LOCATION', text: 'Location' },
  { key: 'CARRIER_ATTRIBUTE', text: 'Carrier Attribute' },
  { key: 'CUSTOMER_ATTRIBUTE', text: 'Customer Attribute' }
];