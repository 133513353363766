import { Table, Button, Icon, Label, TableRowProps } from "semantic-ui-react";
import styled from "styled-components";
import { TURVO_SHIPMENT_URL } from "shared/constants";
import { replacePathParamInUrl } from "shared/utils/utility";
import moment from "moment";

interface MatchesTableRowProps extends TableRowProps {
  item: {
    destination: string;
    equipment: string;
    hasUnseenNotifications: boolean;
    id: number;
    matches: number;
    origin: string;
    pickupDate: string;
    rate: number;
    turvoShipmentId: string;
    bids: number;
  };
}

const MatchesTableRow = (props: MatchesTableRowProps) => {
  const { item, ...rest } = props;

  const _getDate = (date: Date | string) => {
    const momentDate = moment(date);
    return `${momentDate.format("MM")}/${momentDate.format(
      "DD",
    )}/${momentDate.format("YY")}`;
  };

  return (
    <Table.Row {...rest}>
      <ZeroWidthCellStyled textAlign="center" style={{ position: "relative" }}>
        {item.hasUnseenNotifications && (
          <NotificationLabelStyled color="blue" circular empty />
        )}

        <LinkStyled
          href={replacePathParamInUrl(TURVO_SHIPMENT_URL, item.turvoShipmentId)}
          target="_blank"
        >
          {item.turvoShipmentId}
        </LinkStyled>
      </ZeroWidthCellStyled>

      <NoWrapCellStyled>{item.origin}</NoWrapCellStyled>

      <NoWrapCellStyled>{item.destination}</NoWrapCellStyled>

      <ZeroWidthCellStyled textAlign="center">
        {_getDate(item.pickupDate)}
      </ZeroWidthCellStyled>

      <ZeroWidthCellStyled textAlign="center">{"-/-"}</ZeroWidthCellStyled>

      <NoWrapCellStyled>{item.equipment}</NoWrapCellStyled>

      <ZeroWidthCellStyled textAlign="center">
        {item.matches || 0}
      </ZeroWidthCellStyled>

      <ZeroWidthCellStyled textAlign="center">
        {item.bids || 0}
      </ZeroWidthCellStyled>

      <ZeroWidthCellStyled textAlign="center">
        <Icon name="mail" disabled />
        <Button size="mini" compact primary disabled>
          Bid
        </Button>
      </ZeroWidthCellStyled>
    </Table.Row>
  );
};

export { MatchesTableRow };

const ZeroWidthCellStyled = styled(Table.Cell)`
  width: 0;
  white-space: nowrap;
`;

const NoWrapCellStyled = styled(Table.Cell)`
  white-space: nowrap;
`;

const LinkStyled = styled(Table.Cell)`
  font-weight: 600;
  text-decoration: underline;
  color: #4183c4;
  cursor: pointer;
`;

const NotificationLabelStyled = styled(Label)`
  position: absolute;
  left: 5px;
  top: 3px;
`;
