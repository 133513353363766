import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {
  CarrierRateDto,
  TurvoLocation,
} from "pages/RoutingGuide/RoutingGuideTypes";

interface CarrierRateRequest {
  page?: number;
  pageSize?: number;
}

export const getCarrierRateOptions = () => {
  return axios.get(`/api/CarrierRate/options`);
};

export const getTurvoLocationOptions = async (searchTerm: string) => {
  try {
    const response = await axios.get<TurvoLocation[]>(
      `/api/LocationType/turvolocation`,
      {
        params: {
          turvoLocationQuery: searchTerm,
        },
      }
    );
    return response.data.map((location: TurvoLocation) => {
      return {
        key: location.id,
        title: `${location.name} (ID: ${location.id})`,
        description: location.addressLine1,
      };
    });
  } catch (err) {
    console.error(err);
  }
};

export const getTurvoLocationById = async (turvoId: string) => {
  try {
    const response = await axios.get<TurvoLocation>(
      `/api/LocationType/turvolocation/${turvoId}`
    );
    const location = response.data;
    return {
      key: location.id,
      title: `${location.name} (ID: ${location.id})`,
      description: location.addressLine1,
    };
  } catch (err) {
    console.error(err);
  }
};

export const getCarrierRateInfoByID = (carrierRateId: number | string) => {
  return axios.get(`/api/CarrierRate/${carrierRateId}`);
};

export const getAvailableCarrierRatesForRG = (
  routingGuideId: number | string
) => {
  return axios.get(`/api/CarrierRate/available/${routingGuideId}`);
};

export const getCarrierRateList = async (
  carrierId: string,
  request: CarrierRateRequest
) => {
  try {
    const response = await axios.get("/api/CarrierRate/list", {
      params: {
        carrierId,
        page: request.page ?? 1,
        pageSize: request.pageSize ?? 10,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const createCarrierRate = async (requestBody: CarrierRateDto) => {
  try {
    const res = await axios.post(`/api/CarrierRate`, requestBody);
    toast.success("Carrier rate created successfully!");
    return res;
  } catch (error) {
    toast.error("There was an error in creating the carrier rate.");
    throw error;
  }
};

export const updateCarrierRate = async (requestBody: CarrierRateDto) => {
  try {
    const res: AxiosResponse = await axios.put(`/api/CarrierRate`, requestBody);
    toast.success("Carrier rate updated successfully!");
    return res;
  } catch (error) {
    toast.error("There was an error in updating the carrier rate.");
    throw error;
  }
};

export const removeCarrierRateItem = async (carrierRateId: number | string) => {
  await axios
    .delete(`/api/CarrierRate?carrierRateId=${carrierRateId}`)
    .then(() => {
      toast.success("Carrier rate deleted successfully!");
    })
    .catch(() => {
      toast.error("There was an error in deleting the carrier rate.");
    });
};

export const connectCarrierRateToRG = async (requestBody: {
  profilesRoutingGuideId: number;
  carrierRatePriority: { profilesCarrierRateId: number; priority: number }[];
}) => {
  try {
    await axios.post(`api/RoutingGuideCarrierRate`, requestBody);
    toast.success("Successfully connected carrier rate to routing guide!");
  } catch (error) {
    toast.error(
      "There was an error in connecting carrier rate to routing guide."
    );
    throw error;
  }
};

export const getAttachedRoutingGuidesForCarrierRate = async (
  carrierRateId: number,
  page = 1,
  pageSize = 10
) => {
  try {
    const res = await axios.post(
      `api/RoutingGuideCarrierRate/${carrierRateId}/routing-guide/list`,
      {
        page,
        pageSize,
      }
    );
    return res.data;
  } catch (error) {
    console.error(error);
    return { filteredCount: 0, entities: [] };
  }
};
