// styles
import cx from "classnames";
import styles from "./CarrierStar.module.scss";
// utils
import { Address } from "../../models/Address";
import { Email } from "../../models/Email";
import { PhoneData } from "../../models/Phone";

export interface StarredBy {
  addresses: Address[];
  authUserId: string | number | null;
  city: string;
  countryCode: string;
  defaultCapacityMode: string;
  emails: Email[];
  firstName: string;
  iconUrl: string;
  id: number;
  language: string;
  lastName: string;
  phone: string;
  phoneCountryCode: string;
  phoneNumber: string;
  phones: PhoneData[];
  state: string;
  timelineNotes: string;
  unitMeasurementSystem: number;
  zipCode: string;
}

interface CarrierStarProps {
  starredByCurrentUser: boolean;
  starredByOthers: StarredBy[];
}

const CarrierStar = ({
  starredByCurrentUser,
  starredByOthers,
}: CarrierStarProps) => {
  return (
    <div className={styles.carriersStarContainer}>
      {starredByOthers && starredByOthers.length > 0 && (
        <div
          className={cx(
            styles.carrierStar,
            starredByCurrentUser ? styles.doubleGrey : styles.grey
          )}
        >
          <span>★</span>
          <div className={styles.otherStarredList}>
            <div>Starred by:</div>
            {starredByOthers.map((el) => (
              <div
                key={`${el.firstName}_${el.lastName}_${crypto.randomUUID()}`}
              >
                {el.firstName} {el.lastName}
              </div>
            ))}
          </div>
        </div>
      )}
      {starredByCurrentUser && (
        <div
          className={cx(
            styles.carrierStar,
            starredByCurrentUser && starredByOthers
              ? styles.doubleYellow
              : styles.yellow
          )}
        >
          ★
        </div>
      )}
    </div>
  );
};

export default CarrierStar;
