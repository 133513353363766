import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as marketplaceAPI from "api/marketplaceAPI";
import { RootState } from "redux/store/store";
import { Carrier } from "../../models/Carrier";

interface CarrierList {
  data: Carrier[];
  loading: boolean;
  error: string;
}

interface MarketplaceState {
  carrierList: CarrierList;
  updateOrgLoading: boolean;
}

interface UpdateCarrierActiveStatusProps {
  marketplaceId: string;
  organizationName: string;
  profilesCarrierId: number;
  reason: string;
  handleCloseModalAfterUpdate: () => void;
}

export const fetchCarrierList = createAsyncThunk<
  any[],
  any,
  {
    state: RootState;
  }
>("marketplace/fetchCarrierList", async (filter) => {
  const customerResponse = await marketplaceAPI.fetchCarrierList(filter);
  return customerResponse.data?.data?.data ?? [];
});

export const updateCarrierActiveStatus = createAsyncThunk<
  unknown,
  UpdateCarrierActiveStatusProps
>(
  "marketplace/updateCarrierActiveStatus",
  async (filter: UpdateCarrierActiveStatusProps) => {
    await marketplaceAPI.updateCarrierActiveStatus(filter);
  }
);

const initialState: MarketplaceState = {
  carrierList: {
    data: [],
    loading: false,
    error: "",
  },
  updateOrgLoading: false,
};

export const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCarrierList.pending, (state) => {
      state.carrierList.loading = true;
    });
    builder.addCase(fetchCarrierList.fulfilled, (state, action) => {
      state.carrierList.loading = false;
      state.carrierList.data = action.payload;
    });
    builder.addCase(fetchCarrierList.rejected, (state) => {
      state.carrierList.loading = false;
    });
    builder.addCase(updateCarrierActiveStatus.pending, (state) => {
      state.updateOrgLoading = true;
    });
    builder.addCase(updateCarrierActiveStatus.fulfilled, (state) => {
      state.updateOrgLoading = false;
    });
    builder.addCase(updateCarrierActiveStatus.rejected, (state) => {
      state.updateOrgLoading = false;
    });
  },
});

export const actions = {
  ...marketplaceSlice.actions,
  fetchCarrierList,
  updateCarrierActiveStatus,
};

export const reducer = marketplaceSlice.reducer;
