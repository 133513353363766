export interface DataCarrierRate<T> {
  [key: string]: T;
}

export const EMPTY_DATA_CARRIER_RATE: DataCarrierRate<
  string | { equipmentId: number; profilesCarrierRateId: number }[]
> = {
  name: "",
  originType: "",
  originState: "",
  originZip5: "",
  originZip3: "",
  destinationType: "",
  destinationState: "",
  destinationZip5: "",
  destinationZip3: "",
  equipments: [],
  originCountry: "",
  destinationCountry: "",
  mode: "TL",
  effectiveDate: "",
  expirationDate: "",
  rateType: "",
  status: "Active",
  rate: "",
};

export function validateObject({ obj }: { obj: DataCarrierRate<string> }) {
  const zipPostalValidation = (country: string) => ({
    lowerThan: country === "CA" ? 7 : 6,
    biggerThan: country === "CA" ? 5 : 4,
  });

  const isValidString = (str: string) => (str ?? "").trim().length > 0;
  const isValidLength = (
    str: string,
    { lowerThan, biggerThan }: { lowerThan: number; biggerThan: number }
  ) => str.trim().length > biggerThan && str.trim().length < lowerThan;

  const separateWords = (input: string): string =>
    input
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");

  const validateZip = (type: string, zip: string, country: string) =>
    type === "FiveDigitZipCode" &&
    !isValidLength(zip, zipPostalValidation(country))
      ? `Zip-5 is required for ${separateWords(type).toLowerCase()} type.`
      : type === "ThreeDigitZipCode" &&
          !isValidLength(zip, { lowerThan: 4, biggerThan: 2 })
        ? `Zip-3 is required for ${separateWords(type).toLowerCase()} type.`
        : "";

  const compareDates = (dateString1: string, dateString2: string) => {
    const date1 = new Date(dateString1 ?? "");
    const date2 = new Date(dateString2 ?? "");

    if (date1 > date2) {
      return "effectiveDate is later";
    } else {
      return "everything is fine";
    }
  };

  const errors = [];

  const {
    name,
    originType,
    originState,
    originZip5,
    originZip3,
    originCountry,
    destinationType,
    destinationState,
    destinationZip5,
    destinationZip3,
    destinationCountry,
    effectiveDate,
    expirationDate,
    rateType,
    rate,
  } = obj;

  if (!isValidString(name)) {
    errors.push("Name is required.");
  }

  if (!isValidString(originType)) {
    errors.push("Origin Type is required.");
  } else if (originType === "State" && !isValidString(originState)) {
    errors.push("Origin is required for State origin type.");
  } else {
    const error = validateZip(
      originType,
      originType === "FiveDigitZipCode" ? originZip5 : originZip3,
      originCountry
    );
    if (error) errors.push(`Origin ${error}`);
  }

  if (!isValidString(destinationType)) {
    errors.push("Destination Type is required.");
  } else if (destinationType === "State" && !isValidString(destinationState)) {
    errors.push("Destination is required for State destination type.");
  } else {
    const error = validateZip(
      destinationType,
      destinationType === "FiveDigitZipCode"
        ? destinationZip5
        : destinationZip3,
      destinationCountry
    );
    if (error) errors.push(`Destination ${error}`);
  }

  if (originType === "State" && !isValidString(originCountry)) {
    errors.push("Country is required for State origin type.");
  }

  if (destinationType === "State" && !isValidString(destinationCountry)) {
    errors.push("Country is required for State destination type.");
  }

  if (!isValidString(effectiveDate)) {
    errors.push("Effective Date is required.");
  }
  if (!isValidString(expirationDate)) {
    errors.push("Expiration Date is required.");
  }

  if (isValidString(effectiveDate) && isValidString(expirationDate)) {
    const dateComparison = compareDates(effectiveDate, expirationDate);
    if (dateComparison === "effectiveDate is later") {
      errors.push("Effective Date cannot be later than Expiration Date.");
    }
  }

  if (!isValidString(rateType)) {
    errors.push("Rate Type is required.");
  }
  if (rateType === "FlatRate" && !rate) {
    errors.push("Rate is required.");
  }

  return errors;
}
