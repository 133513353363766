import styles from "pages/Carriers/Carrier.module.scss";
import { CarrierMinifiedList } from "components/CarrierList";
import CapacityMatchingSegment from "components/CapacityMatchingSegment";
import { MouseEvent } from "react";
import { Carrier } from "../../models/Carrier";

interface CarrierQuickViewProps {
  carriers: Carrier[];
  selectedCarrier: Carrier;
  carrierFilter: {
    pagination: {
      page: number;
      pageSize: number;
    }
  };
  carriersLoading: boolean;
  handlePageSizeChange: (_: unknown, { value }: { value: number }) => void;
  handleCarrierRowClick: (carrier: Carrier) => void;
  handleOpenEditCarrier: (carrierId: number) => void;
  handlePageChange: (
    e: MouseEvent<HTMLElement>,
    { activePage }: { activePage: number },
  ) => void;
  handleCarrierMatchClose: () => void;
  handleCarrierUpdate: (carrierId: number) => void;
  dashboardView?: boolean;
}

export const CarrierQuickView = ({
  carriers,
  selectedCarrier,
  carrierFilter,
  carriersLoading,
  handlePageSizeChange,
  handleCarrierRowClick,
  handleOpenEditCarrier,
  handlePageChange,
  handleCarrierMatchClose,
  handleCarrierUpdate,
  dashboardView = false,
}: CarrierQuickViewProps) => {
  return (
    <div className={styles.carrierMinifiedListContainer}>
      <CarrierMinifiedList
        onRowClick={handleCarrierRowClick}
        onEditCarrier={handleOpenEditCarrier}
        carriersLoading={carriersLoading}
        carriers={carriers}
        gridFilter={carrierFilter.pagination}
        handlePageSizeChange={handlePageSizeChange}
        handlePageChange={handlePageChange}
        selectedCarrier={selectedCarrier}
        dashboardView={dashboardView}
      />
      <div
        className={styles.capacityMatchingArea}
        title="capacity-matching-segment"
      >
        <CapacityMatchingSegment
          carrierId={selectedCarrier.id}
          carrierName={selectedCarrier.name}
          onClose={handleCarrierMatchClose}
          onCarrierNoteUpdate={handleCarrierUpdate}
        />
      </div>
    </div>
  );
};
