import { useState, SyntheticEvent, KeyboardEvent } from "react";
import { DatesRangeInput } from "semantic-ui-calendar-react";

interface AvailDatepickerProps {
  value: string;
  onChange: (value: { value: string }) => void;
}

const AvailDatepicker = (props: AvailDatepickerProps) => {
  const { value, onChange, ...restProps } = props;
  const [enterPressed, setEnterPressed] = useState(false);

  const handleChange = (
    _event: SyntheticEvent,
    { value }: { value: string },
  ) => {
    if (!enterPressed) onChange({ value });
    setEnterPressed(false);
  };

  return (
    <DatesRangeInput
      dateFormat="MM/DD/YYYY"
      placeholder="MM/DD/YYYY - MM/DD/YYYY"
      value={value}
      onChange={handleChange}
      onKeyDown={(e: KeyboardEvent) => {
        e.preventDefault();
      }}
      {...restProps}
    />
  );
};

export { AvailDatepicker };
