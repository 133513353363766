import axios, { AxiosError } from "axios";
import useSWR, { SWRResponse } from "swr";

import { QueryGridResponse } from "../../../../../models/QueryGridResponse";
import { CarrierCoveragePlanCapacityDto } from "../../../../../models/dto/CarrierCoveragePlanCapacityDto";

type UseGetCoveragePlanCapacitiesArgs = {
  carrierId: number;
  filter: {
    page: number;
    pageSize: number;
  };
};

export const useGetCoveragePlanCapacities = ({
  carrierId,
  filter,
}: UseGetCoveragePlanCapacitiesArgs): SWRResponse<
  QueryGridResponse<CarrierCoveragePlanCapacityDto>,
  AxiosError
> => {
  const params = new URLSearchParams({
    page: filter.page.toString(),
    pageSize: filter.pageSize.toString(),
    sortField: "createdDate",
    dir: "desc",
  });

  return useSWR<QueryGridResponse<CarrierCoveragePlanCapacityDto>, AxiosError>(
    `/api/carriers/${carrierId}/knownlanes/list/coverageplancapacities?${params.toString()}`,
    (url) =>
      axios
        .get<QueryGridResponse<CarrierCoveragePlanCapacityDto>>(url)
        .then((response) => response.data),
    { keepPreviousData: true }
  );
};
