import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "./thunks";
import { EMPTY_DATA_CARRIER_RATE } from "components/CarrierRate/components/utils";

interface DataCarrierRateOptions<T> {
  [key: string]: T;
}

export interface DataCarrierRateProps {
  id: number;
  destinationState: string;
  destinationLat: number;
  destinationLong: number;
  destinationType: string;
  destinationZip3: string;
  destinationZip5: string;
  effectiveDate: string;
  expirationDate: string;
  equipments: {
    equipmentId: number;
    profilesCarrierRateId: number;
  }[];
  originState: string;
  originLat: number;
  originLong: number;
  originType: string;
  originZip3: string;
  originZip5: string;
  profilesCarrierId: number;
  rate: number | string;
  rateType: string;
  status: string;
  name: string;
  mode: string;
}

interface StateProps {
  carrierId: string | number;
  carrierRateOptions: {
    data: {
      locationTypes: DataCarrierRateOptions<string>[];
      equipmentTypes: DataCarrierRateOptions<string | number>[];
      countries: DataCarrierRateOptions<string>[];
      rateTypes: DataCarrierRateOptions<string>[];
      rateStatus: DataCarrierRateOptions<string>[];
      canadaStates: DataCarrierRateOptions<string>[];
      mexicoStates: DataCarrierRateOptions<string>[];
      usStates: DataCarrierRateOptions<string>[];
    };
    loading: boolean;
    error: null | string;
  };
  carrierRate: {
    data: DataCarrierRateProps[];
    loading: boolean;
    error: null | string;
    page: number;
    pageSize: number;
    total: number;
    carrierRateLoadingId: number | string;
  };
}

const carrierRateSlice = createSlice({
  name: "capacityMatching",
  initialState: {
    carrierId: "",
    carrierRateOptions: {
      loading: false,
      data: {
        locationTypes: [],
        equipmentTypes: [],
        countries: [],
        rateTypes: [],
        rateStatus: [],
        canadaStates: [],
        mexicoStates: [],
        usStates: [],
      },
      error: null,
    },
    carrierRate: {
      data: [],
      loading: false,
      error: null,
      page: 1,
      pageSize: 10,
      total: 0,
      carrierRateLoadingId: "",
    },
  },
  reducers: {
    setCarrierPageSize: (state, action) => {
      state.carrierRate.pageSize = action.payload;
      state.carrierRate.page = 1;
    },
    setCarrierPage: (state, action) => {
      state.carrierRate.page = action.payload;
    },
    addNewCarrierRateInfo: (state) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.carrierRate.data.unshift({ id: 0, ...EMPTY_DATA_CARRIER_RATE });
    },
    cancelCarrierRateCreation: (state) => {
      state.carrierRate.data = [
        ...state.carrierRate.data.filter(
          (item: { id: number }) => item.id !== 0
        ),
      ];
    },
    setCurrentCarrierID: (state, _action) => {
      state.carrierId = _action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      thunks.getCarrierRateList.pending,
      (state: StateProps, action: any) => {
        state.carrierRate.loading = true;
        if (action.meta.arg.requestBody?.page > 0) {
          state.carrierRate.page = action.meta.arg.requestBody.page;
        }
        if (action.meta.arg.requestBody?.pageSize > 0) {
          state.carrierRate.pageSize = action.meta.arg.requestBody.pageSize;
        }
      }
    );
    builder.addCase(
      thunks.getCarrierRateList.fulfilled,
      (state: StateProps, action) => {
        state.carrierRate.loading = false;
        state.carrierRate.data = action.payload.entities;
        state.carrierRate.total = action.payload.filteredCount;
      }
    );
    builder.addCase(thunks.getCarrierRateList.rejected, (state: StateProps) => {
      state.carrierRate.loading = false;
      state.carrierRate.error =
        "Error occurred while fetching carrier rate list!";
    });

    builder.addCase(
      thunks.createCarrierRateInfo.pending,
      (state: StateProps) => {
        state.carrierRate.loading = true;
      }
    );
    builder.addCase(
      thunks.createCarrierRateInfo.fulfilled,
      (state: StateProps, action: any) => {
        const updatedList = [
          ...state.carrierRate.data.map((item) =>
            item.id === 0 ? { ...action.payload } : item
          ),
        ];
        if (updatedList.length > state.carrierRate.pageSize) {
          updatedList.pop();
        }

        state.carrierRate.data = updatedList;
        state.carrierRate.total++;
        state.carrierRate.loading = false;
      }
    );
    builder.addCase(
      thunks.createCarrierRateInfo.rejected,
      (state: StateProps) => {
        const updatedList = [
          ...state.carrierRate.data.filter((item) => item.id !== 0),
        ];

        state.carrierRate.loading = false;
        state.carrierRate.error =
          "Error occurred while creating carrier rate info!";
        state.carrierRate.data = updatedList;
      }
    );

    builder.addCase(
      thunks.updateCarrierRateInfo.pending,
      (state: StateProps) => {
        state.carrierRate.loading = true;
      }
    );
    builder.addCase(
      thunks.updateCarrierRateInfo.fulfilled,
      (state: StateProps, action: any) => {
        state.carrierRate.data = [
          ...state.carrierRate.data.map((item) =>
            item.id === 0 ? { ...action.payload } : item
          ),
        ];
        state.carrierRate.loading = false;
      }
    );
    builder.addCase(
      thunks.updateCarrierRateInfo.rejected,
      (state: StateProps) => {
        state.carrierRate.loading = false;
        state.carrierRate.error =
          "Error occurred while updating carrier rate info!";
        state.carrierRate.carrierRateLoadingId = "";
      }
    );

    builder.addCase(
      thunks.deleteCarrierRateItem.pending,
      (state: StateProps) => {
        state.carrierRate.loading = true;
      }
    );
    builder.addCase(
      thunks.deleteCarrierRateItem.fulfilled,
      (state: StateProps) => {
        state.carrierRate.loading = false;
      }
    );
    builder.addCase(
      thunks.deleteCarrierRateItem.rejected,
      (state: StateProps) => {
        state.carrierRate.loading = true;
        state.carrierRate.error = "Error occurred while deleting carrier rate!";
      }
    );

    builder.addCase(
      thunks.getCarrierRateOptions.pending,
      (state: StateProps) => {
        state.carrierRateOptions.loading = true;
      }
    );
    builder.addCase(
      thunks.getCarrierRateOptions.fulfilled,
      (state: StateProps, action) => {
        state.carrierRateOptions.loading = false;
        state.carrierRateOptions.data = action.payload;
      }
    );
    builder.addCase(
      thunks.getCarrierRateOptions.rejected,
      (state: StateProps) => {
        state.carrierRateOptions.loading = true;
        state.carrierRateOptions.error =
          "Error occurred while fetching options!";
      }
    );
  },
});

export const actions = {
  ...carrierRateSlice.actions,
  ...thunks,

  setCarrierRateInfoPageAct2: (payload: {
    page: number;
    pageSize: number;
    carrierId: string;
  }) => {
    return carrierRateSlice.actions.setCarrierPage(payload.page);
  },
  setCarrierPageSizeAct: (payload: { pageSize: number }) => {
    return carrierRateSlice.actions.setCarrierPageSize(payload.pageSize);
  },
};

export const reducer = carrierRateSlice.reducer;
