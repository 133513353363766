import axios from "axios";
import { toast } from "react-toastify";
import { CarrierCapacityInfoDto } from "../models/dto/CarrierCapacityInfoDto";

type CapacityRequest = {
  page?: number;
  pageSize?: number;
}

export function getEquipmentType() {
  return axios.get(`/api/options/equipment`);
}

export function getCapacityInfoByCarrierId(carrierId: number, requestBody = {}) {
  return axios.post(`/api/carriers/${carrierId}/knownLanes/list`, requestBody);
}

export async function createCapacityInfo(carrierId: number, requestBody: CarrierCapacityInfoDto) {
  try {
    const res = await axios.post(
      `/api/carriers/${carrierId}/knownLanes`,
      requestBody,
    );
    toast.success("Capacity details have been saved!");
    return res;
  } catch {
    return toast.error("Error occured while saving capacity details!");
  }
}

export async function updateCapacityInfo(carrierId: number, requestBody: CarrierCapacityInfoDto) {
  try {
    await axios.put(`/api/carriers/${carrierId}/knownLanes`, requestBody);
    toast.success("Capacity details have been saved!");
  } catch {
    return toast.error("Error occured while saving capacity details!");
  }
}

export function triggerCapacityMatchingAlgo(carrierId: number, capacityInfoId: number) {
  return axios.post(
    `/api/carriers/${carrierId}/knownLanes/generateCarrierCapacityMatches/${capacityInfoId}`
  )
}

export function triggerCarrierCapacityMatchesAutomate(carrierId: number, capacityInfoId: number) {
  return axios.post(
    `/api/carriers/${carrierId}/knownLanes/getCarrierCapacityMatchesAutomate/${capacityInfoId}`
  );
}

export function getCapacityMatchingLog(
  carrierId: number,
  capacityInfoId: number,
  request: CapacityRequest
) {
  // Create URLSearchParams directly with the request object
  const queryParams = new URLSearchParams({
    ...(request.page !== undefined && { page: request.page.toString() }),
    ...(request.pageSize !== undefined && { pageSize: request.pageSize.toString() })
  });

  const query = `/api/carriers/${carrierId}/knownLanes/${capacityInfoId}/matches?${queryParams.toString()}`;

  return axios.get(query);
}

export function removeCapacityItem(carrierId: number, capacityItemId: number) {
  return axios.delete(
    `/api/carriers/${carrierId}/knownLanes/${capacityItemId}`
  );
}

export function getLocations(searchValue: string) {
  return axios.get(`/api/shipments/SearchLocation?searchPhrase=${searchValue}`);
}

export function updateActivityNotes({noteMessage, carrierId}: { noteMessage: string, carrierId: number }) {
  return axios.post(`/api/carrier/${carrierId}/timeline/notes`, {
    note: noteMessage,
  });
}
