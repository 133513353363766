import { Segment, Header, Image } from "semantic-ui-react";
import { generateIcon } from "shared/utils/utility";
import styles from "./TableProfileImage.module.scss";
import { Activity } from "../../models/Activity";
import { Address } from "../../models/Address";
import { Email } from "../../models/Email";
import { PhoneData } from "../../models/Phone";

export interface ItemProps {
  iconUrl: string;
  id: number;
  isRemarketing: boolean;
  lastActivity: Activity;
  name: string;
  primaryAddress: Address;
  primaryEmail: Email;
  primaryPhone: PhoneData;
  requiresPaperworkImage: boolean;
}

interface TableProfileImageProps {
  item: ItemProps;
  customContent?: React.ReactNode;
}

const TableProfileImage = ({ item, customContent }: TableProfileImageProps) => {
  return (
    <div className={styles.iconContainer}>
      {item.iconUrl ? (
        <div className={styles.imageBar}>
          <Image
            title="profileImage"
            circular
            src={item.iconUrl}
            className={styles.image}
          />
        </div>
      ) : (
        <Segment circular className={styles.profileIcon}>
          <Header as="h1" className={styles.profileTitle}>
            {customContent ?? generateIcon(item)}
          </Header>
        </Segment>
      )}
    </div>
  );
};

export default TableProfileImage;
