import axios, { AxiosError } from "axios";
import useSWR, { SWRResponse } from "swr";

import { CoveragePlanDto } from "../../../../../models/dto/CoveragePlanDto";

export const useGetCoveragePlan = (
  id: number
): SWRResponse<CoveragePlanDto, AxiosError> => {
  return useSWR<CoveragePlanDto, AxiosError>(
    `/api/coverageplan/${id}`,
    (url) => axios.get<CoveragePlanDto>(url).then((response) => response.data),
    { keepPreviousData: true }
  );
};
