import { Table } from "semantic-ui-react";
import styled from "styled-components";

const CapacityTableHeader = (shadow) => {
  return (
    <CapacityHeaderStyled shadow={shadow ? 1 : 0}>
      <Table.Row>
        <CapacityHeaderCellStyled />
        <CapacityHeaderCellStyled>Type</CapacityHeaderCellStyled>
        <CapacityHeaderCellStyled style={{ whiteSpace: "nowrap" }}>
          Origin / radius
        </CapacityHeaderCellStyled>
        <CapacityHeaderCellStyled style={{ whiteSpace: "nowrap" }}>
          Dest. / radius
        </CapacityHeaderCellStyled>
        <CapacityHeaderCellStyled style={{ whiteSpace: "nowrap" }}>
          Avail Date - Exp Date
        </CapacityHeaderCellStyled>
        <CapacityHeaderCellStyled>Rate</CapacityHeaderCellStyled>
        <CapacityHeaderCellStyled>Equipment</CapacityHeaderCellStyled>
        <CapacityHeaderCellStyled>Matches</CapacityHeaderCellStyled>
        <CapacityHeaderCellStyled>Bids</CapacityHeaderCellStyled>
        <CapacityHeaderCellStyled style={{ whiteSpace: "nowrap" }}>
          Is Autogenerated
        </CapacityHeaderCellStyled>
        <CapacityHeaderCellStyled>Expired</CapacityHeaderCellStyled>
        <CapacityHeaderCellStyled />
      </Table.Row>
    </CapacityHeaderStyled>
  );
};

export { CapacityTableHeader };

const CapacityHeaderStyled = styled(Table.Header)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  font-size: 1.1em;
  font-weight: 600;
  box-shadow: ${(props) =>
    props.shadow ? "0px 1px 10px 0px rgb(0 0 0 / 25%) !important" : "none"};
  clip-path: ${(props) => (props.shadow ? "inset(0px 0px -10px 0px)" : "none")};
`;

const CapacityHeaderCellStyled = styled(Table.Cell)`
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
`;
