import { Table, Button } from "semantic-ui-react";
import styled from "styled-components";

interface MatchesTableHeaderProps {
  shadow: boolean;
  onCapacityInfoRefresh: () => void;
  onClose: () => void;
}

const MatchesTableHeader = (props: MatchesTableHeaderProps) => {
  const { shadow, onCapacityInfoRefresh, onClose } = props;

  return (
    <MatchesHeaderStyled shadow={shadow ? 1 : 0}>
      <Table.Row>
        <TableTitleCellStyled textAlign="left" colSpan="1">
          Matches
        </TableTitleCellStyled>
        <TableTitleCellStyled textAlign="right" colSpan="8">
          <Button.Group compact size="small" onClick={onCapacityInfoRefresh}>
            <Button color="blue">Refresh</Button>
            <CloseButtonStyled basic onClick={onClose}>
              Close
            </CloseButtonStyled>
          </Button.Group>
        </TableTitleCellStyled>
      </Table.Row>
      <Table.Row>
        <MatchesHeaderCellStyled style={{ whiteSpace: "nowrap" }}>
          Shipment Id
        </MatchesHeaderCellStyled>
        <MatchesHeaderCellStyled style={{ whiteSpace: "nowrap" }}>
          Origin
        </MatchesHeaderCellStyled>
        <MatchesHeaderCellStyled style={{ whiteSpace: "nowrap" }}>
          Destination
        </MatchesHeaderCellStyled>
        <MatchesHeaderCellStyled style={{ whiteSpace: "nowrap" }}>
          Pick. Date
        </MatchesHeaderCellStyled>
        <MatchesHeaderCellStyled title="Start / Max">
          S/M
        </MatchesHeaderCellStyled>
        <MatchesHeaderCellStyled>Equipment</MatchesHeaderCellStyled>
        <MatchesHeaderCellStyled>Matches</MatchesHeaderCellStyled>
        <MatchesHeaderCellStyled>Bids</MatchesHeaderCellStyled>
        <MatchesHeaderCellStyled />
      </Table.Row>
    </MatchesHeaderStyled>
  );
};

export { MatchesTableHeader };

const MatchesHeaderStyled = styled(Table.Header)`
  position: sticky;
  top: 0;
  z-index: 30;
  background-color: white;
  font-size: 1.1em;
  font-weight: 600;
  box-shadow: ${(props) =>
    props.shadow ? "0px 1px 10px 0px rgb(0 0 0 / 25%) !important" : "none"};
  clip-path: ${(props) => (props.shadow ? "inset(0px 0px -10px 0px)" : "none")};
`;

const MatchesHeaderCellStyled = styled(Table.Cell)`
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
`;

const TableTitleCellStyled = styled(Table.Cell)`
  padding-top: 3px !important;
  padding-bottom: 3px !important;
`;

const CloseButtonStyled = styled(Button)`
  box-shadow: inset 0 0 0 1px rgb(34 36 38 / 15%) !important;
`;
