// components
import { MarketplaceIcon } from "./MarketplaceIcon";
import { Profile } from "oidc-client";

type Attributes = {
  attributeName: string;
  attributeType: string;
  deleted: boolean;
  profilesAttributeId: number;
  value: string | boolean;
}

const availableOrderedAttributes = ["active", "registered", "invited"];

export const validateAttributeValue = (value: unknown): boolean => {
  if (typeof value === "string") {
    switch (value) {
      case "False":
      case "false":
        return false;
      default:
        return true;
    }
  } else {
    return Boolean(value);
  }
};

export const getMarketPlaceState = (profile: Profile): string | null => {
  for (const attributeName of availableOrderedAttributes) {
    if ((profile.attributes ?? []).some((attribute: Attributes) =>
        attribute.attributeName.toLocaleLowerCase() === attributeName &&
        validateAttributeValue(attribute.value)
    )) {
      return attributeName;
    }
  }

  return null;
};

export const MarketplaceCarrierIcon = (props: { profile: Profile }) => {
  const { profile } = props;

  return (
      <MarketplaceIcon state={getMarketPlaceState(profile)} />
  );
};