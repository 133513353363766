import { useRef, useState, useEffect, MutableRefObject } from "react";
import { Table, Dimmer, Loader } from "semantic-ui-react";
import styled from "styled-components";

import { MatchesTableHeader } from "./MatchesTableHeader";
import { MatchesTableRow } from "./MatchesTableRow";
import { MatchesTableNoDataRow } from "./MatchesTableNoDataRow";

interface MatchesTableProps {
  data: {
    destination: string;
    equipment: string;
    hasUnseenNotifications: boolean;
    id: number;
    matches: number;
    origin: string;
    pickupDate: string;
    rate: number;
    turvoShipmentId: string;
    bids: number
  }[];
  loading: boolean;
  onCapacityInfoRefresh: () => void;
  onClose: () => void;
  selectedCapacityId: number;
}

const MatchesTable = (props: MatchesTableProps) => {
  const { data, loading, onCapacityInfoRefresh, onClose, selectedCapacityId } =
    props;
  const scrollerRef = useRef<HTMLElement | null>(null);
  const [shadow, setShadow] = useState(false);

  useEffect(() => {
    const id = setInterval(() => onCapacityInfoRefresh(), 1000 * 60);
    return () => clearInterval(id);
  }, [onCapacityInfoRefresh, selectedCapacityId]);

  const handleTableScroll = () => {
    if (!scrollerRef?.current) {
      return;
    }

    if (!shadow && scrollerRef.current.scrollTop > 0) {
      setShadow(true);
    }

    if (shadow && scrollerRef.current.scrollTop === 0) {
      setShadow(false);
    }
  };

  return (
    <MatchesTableContainerStyled
      ref={scrollerRef as MutableRefObject<HTMLDivElement>}
      onScroll={() => {
        handleTableScroll();
      }}
    >
      {!loading && (
        <MatchesTableStyled striped selectable>
          <MatchesTableHeader
            shadow={shadow}
            onCapacityInfoRefresh={onCapacityInfoRefresh}
            onClose={onClose}
          />
          <Table.Body>
            {(!data || data.length === 0) && <MatchesTableNoDataRow />}
            {data.map((item, idx) => (
              <MatchesTableRow
                key={item.id}
                tabIndex={idx === 0 ? 0 : -1}
                item={item}
                idx={idx}
              />
            ))}
          </Table.Body>
        </MatchesTableStyled>
      )}
      <Dimmer active={loading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    </MatchesTableContainerStyled>
  );
};

export { MatchesTable };

const MatchesTableContainerStyled = styled.div`
  flex-grow: 1;
  overflow: auto;
  background-color: #e8ebef;
  position: relative;
  border-top: 1px solid rgba(34, 36, 38, 0.1);
`;

const MatchesTableStyled = styled(Table)`
  border: 0 !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
`;
