import { useRef, useEffect } from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import { PREFERRED_LANE, CAPACITY, STATIC, SPOT } from "shared/constants";

interface TypeDropdownProps extends DropdownProps {
  value: string;
  autoFocus?: boolean;
  focusOnRender?: boolean;
}

const TypeDropdown = (props: TypeDropdownProps) => {
  const { value, autoFocus, ...restProps } = props;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const elementRef = useRef<Dropdown>(null);

  useEffect(() => {
    if (props.focusOnRender && elementRef.current) {
      // Assuming Dropdown component exposes a focus method
      elementRef.current.focus();
    }
  }, [props.focusOnRender]);

  const getValidType = (type: string): string => {
    switch (type) {
      case STATIC:
        return PREFERRED_LANE;
      case SPOT:
        return CAPACITY;
      default:
        return type;
    }
  };

  return (
    <Dropdown
      ref={elementRef}
      value={getValidType(value)}
      {...restProps}
      search
      searchInput={{ autoFocus: autoFocus }}
    />
  );
};

export { TypeDropdown };
