import momentTz from "moment-timezone";
import cx from "classnames";
import { Icon, Label } from "semantic-ui-react";

import { formatPhoneNumberWithExtentionAndCountryCode } from "shared/utils/utility";
import * as activityConstants from "shared/constants/activityConstants";

import CarrierTriangleBadge from "../CarrierTriangleBadge";
import CarrierStar from "components/CarrierStar";
import TableProfileImage from "components/TableProfileImage";

import styles from "./CarrierDividedList.module.scss";

const createContentSummary = (act) => {
  let summaryEntityType =
    activityConstants.entityTypes.find((type) => type.key === act.entityType) &&
    activityConstants.entityTypes.find((type) => type.key === act.entityType)
      .text;

  let summaryAction =
    activityConstants.activityTypes.find(
      (type) => type.key === act.actionType,
    ) &&
    activityConstants.activityTypes.find((type) => type.key === act.actionType)
      .text;

  let user = act.userName ? `${act.userName}: ` : "";

  return { user, summaryAction, summaryEntityType };
};

const MinifiedList = ({ item, onRowClick, onEditCarrier, active }) => {
  const phoneValue = item.phone
    ? formatPhoneNumberWithExtentionAndCountryCode(item.phone)
    : null;

  const lastActivityTime = item?.lastActivity?.createdAt
    ? momentTz
        .utc(item.lastActivity.createdAt)
        .tz("America/New_York")
        .format("MM/DD/YY h:mm A")
    : "never";

  const lastActivityVal = item.lastActivity
    ? createContentSummary(item.lastActivity)
    : null;

  const handleRowClick = (_, item) => {
    onRowClick(item);
  };

  const handleCarrierEdit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onEditCarrier(item.id);
  };

  return (
    <div
      className={cx(styles.carrierRow, active && styles.activeRow)}
      title={active ? "active" : "inactive"}
      id={`minified_list_row_${item.id}`}
      onClick={(e) => handleRowClick(e, item)}
    >
      <div className={styles.cell}>
        <CarrierTriangleBadge
          key={`${item.id}_ctb`}
          turvoId={item.turvoId}
          statusId={item.statusCodeId}
        />
        <TableProfileImage item={item} />
        <CarrierStar
          key={`cstar_${item.id}`}
          starredByCurrentUser={item.isStarredByCurrentUser}
          starredByOthers={item.starredByUsers}
        />
        <Icon
          className={styles.editIconContainer}
          name="edit"
          title="edit-icon"
          link
          onClick={handleCarrierEdit}
        />
      </div>
      <div className={cx(styles.cell, styles.twentyFive)}>
        <p title={item.name || null}>{item.name}</p>
        <p className={styles.subtext}>
          Owner: {item.owner ? item.owner.name : "n/a"}
        </p>
        <p className={styles.subtext}>MC#:{item.mcNumber}</p>
        <p className={styles.subtext}>DOT#:{item.dotNumber}</p>
      </div>
      <div className={cx(styles.cell, styles.thirty)}>
        {phoneValue && (
          <p>
            <Icon name="phone" color="grey" size="small" />
            {phoneValue}
          </p>
        )}
        {item.email && (
          <p title={item.email || null} className={styles.subtext}>
            <Icon name="mail" color="grey" size="small" />
            {item.email}
          </p>
        )}
        {item.address && (
          <p>
            <Icon name="globe" color="grey" size="small" />
            {item.address}
          </p>
        )}
      </div>
      <div className={cx(styles.cell, styles.twentyFive)}>
        <p title={lastActivityTime}>{lastActivityTime}</p>
        {item?.lastActivity?.notes && (
          <p title={item?.lastActivity?.notes} className={styles.subtext}>
            {item?.lastActivity?.notes}
          </p>
        )}
        {!lastActivityVal && <p className={styles.subtext}>none</p>}
        {lastActivityVal?.user && (
          <p className={styles.subtext} title={lastActivityVal?.user}>
            {lastActivityVal.user}
          </p>
        )}
        {lastActivityVal?.summaryAction && (
          <p className={styles.subtext} title={lastActivityVal?.summaryAction}>
            {lastActivityVal.summaryAction}
          </p>
        )}
        {lastActivityVal?.summaryEntityType && (
          <p
            className={styles.subtext}
            title={lastActivityVal?.summaryEntityType}
          >
            {lastActivityVal.summaryEntityType}
          </p>
        )}
      </div>
      <div className={cx(styles.cell, styles.nine)}>
        <p>
          {item.totalActiveMatches || 0}/{item.totalActiveCapacities || 0}
        </p>
      </div>

      {item.unseenNotificationsCount > 0 && (
        <Label className={styles.unseenLabel} circular color="blue" size="tiny">
          {item.unseenNotificationsCount > 99
            ? "99+"
            : item.unseenNotificationsCount}
        </Label>
      )}
    </div>
  );
};
export default MinifiedList;
