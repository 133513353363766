import { Table, Button, Icon, Label, Popup } from "semantic-ui-react";
import styled from "styled-components";

import CapacityTypeIcon from "../CapacityTypeIcon";
import { CAPACITY, SPOT } from "shared/constants";

const CapacityReadRow = (props) => {
  const {
    item,
    equipmentTypeOptions,
    isEditing,
    onDelete,
    onEdit,
    onExpire,
    onSelect,
    ...rest
  } = props;

  const _getAvailExpDate = () =>
    item.type === SPOT || item.type === CAPACITY
      ? `${item.availabilityDate} - ${item.expirationDate || "N/A"}`
      : "N/A";

  const _getRate = () => `$${item.rate || "0"}`;

  const _getEquipType = () =>
    equipmentTypeOptions?.find((el) => el.value === item.equipmentModeCategoryId)
      ?.text || "N/A";

  const GeocodeErrorMessage = (location) => {
    return `Geocode error: couldn't find geocode for your ${location} city. Please fix it!`;
  };
  
  return (
    <Table.Row {...rest}>
      <EditCellStyled textAlign="center">
        <Icon
          onClick={() => onEdit(item.id)}
          name="edit"
          link={!isEditing}
          disabled={isEditing}
          title="edit"
        />
      </EditCellStyled>

      <ZeroWidthCellStyled textAlign="center">
        <CapacityTypeIcon
          type={item.type}
          createdBy={item.createdBy}
          creationDate={item.creationDate}
          notes={item.notes}
        />
      </ZeroWidthCellStyled>

      <NoWrapCellStyled>
        {item.origin} / {item.originRadius}
        <span style={{ marginLeft: "15px" }}>
          {(item.geoCodeError === 1 || item.geoCodeError === 3) && (
            <Popup
              trigger={
                <Icon name="exclamation triangle" color="red" />
              }
              content={GeocodeErrorMessage("origin")}
            />
          )}
        </span>
      </NoWrapCellStyled>

      <NoWrapCellStyled>
        {item.destination} / {item.destinationRadius}
        <span style={{ marginLeft: "15px" }}>
          {(item.geoCodeError === 2 || item.geoCodeError === 3) && (
            <Popup
              trigger={
                <Icon name="exclamation triangle" color="red" />
              }
              content={GeocodeErrorMessage("destination")}
            />
          )}
        </span>
      </NoWrapCellStyled>

      <NoWrapCellStyled>
        {_getAvailExpDate()}
      </NoWrapCellStyled>

      <ZeroWidthCellStyled>{_getRate()}</ZeroWidthCellStyled>

      <NoWrapCellStyled>{_getEquipType()}</NoWrapCellStyled>

      <MatchesCellStyled textAlign="center" onClick={() => onSelect(item.id)}>
        <MatchesValue item={item} />
      </MatchesCellStyled>

      <ZeroWidthCellStyled textAlign="center">
        {item.bids || 0}
      </ZeroWidthCellStyled>

     

      <ZeroWidthCellStyled textAlign="center" >
        {item.autoGenerated | 0}
      </ZeroWidthCellStyled>

      <ZeroWidthCellStyled>
        <Button size="mini" onClick={() => onExpire(item)} primary compact>
          Expire
        </Button>
      </ZeroWidthCellStyled>

      <DeleteCellStyled textAlign="center">
        <Icon
          name="trash alternate"
          title="delete"
          link={!isEditing}
          onClick={() => onDelete(item.id)}
          disabled={isEditing}
        />
      </DeleteCellStyled>
    </Table.Row>
  );
};

export { CapacityReadRow };

const MatchesValue = ({ item }) => (
  <MatchesSpanStyled>
    {item.matches || 0}
    {item.unseenNotificationsCount > 0 && (
      <MatchesNotificationStyled circular color="blue" size="tiny">
        {item.unseenNotificationsCount > 99
          ? "99+"
          : item.unseenNotificationsCount}
      </MatchesNotificationStyled>
    )}
  </MatchesSpanStyled>
);

export { MatchesValue };

const NoWrapCellStyled = styled(Table.Cell)`
  white-space: nowrap;
`;

const ZeroWidthCellStyled = styled(Table.Cell)`
  width: 0;
`;

const MatchesCellStyled = styled(Table.Cell)`
  width: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #4183c4;
  }
`;

const MatchesSpanStyled = styled.span`
  color: #4183c4;
  font-weight: 600;
  z-index: 0;
  position: relative;
`;

const MatchesNotificationStyled = styled(Label)`
  position: absolute;
  top: -13px;
  right: -25px;
`;

const EditCellStyled = styled(Table.Cell)`
  width: 0;
  padding-right: 0 !important;
`;

const DeleteCellStyled = styled(Table.Cell)`
  width: 0;
  padding-left: 0 !important;
`;
