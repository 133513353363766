import { useForm } from "react-hook-form";
import { KeyedMutator } from "swr";
import { toast } from "react-toastify";
import { Button, Dimmer, Loader, Modal } from "semantic-ui-react";

import CarrierCoveragePlanForm, {
  CarrierCoveragePlanFormObject,
} from "./CarrierCoveragePlanForm";
import { useGetCoveragePlans } from "../CoveragePlanForm/api/hooks/useGetCoveragePlans";
import { QueryGridResponse } from "../../../models/QueryGridResponse";
import { CarrierCoveragePlanCapacityDto } from "../../../models/dto/CarrierCoveragePlanCapacityDto";
import { mapFormToApiObject } from "./utils/coveragePlanCapacityFormToApiMappers";
import {
  postCoveragePlanCapacity,
  putCoveragePlanCapacity,
} from "./api/coveragePlanCapacityFormApiCalls";

type CoveragePlanFormModalProps = {
  onClose: () => void;
  carrierId: number;
  carrierName: string;
  defaultValues: CarrierCoveragePlanFormObject;
  reloadCapacities: KeyedMutator<
    QueryGridResponse<CarrierCoveragePlanCapacityDto>
  >;
};

const CarrierCoveragePlanFormModal = (props: CoveragePlanFormModalProps) => {
  const { data, isLoading } = useGetCoveragePlans({
    statuses: ["Active"],
    page: 1,
    pageSize: 100,
    sortField: "name",
    dir: "asc",
  });

  const {
    control,
    errors,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CarrierCoveragePlanFormObject>({
    defaultValues: props.defaultValues,
  });

  const onSubmit = async (formObject: CarrierCoveragePlanFormObject) => {
    const apiObject = mapFormToApiObject(formObject);

    if (!apiObject) {
      toast.error(
        "There was an error in form validation.  Please check your entries and try again."
      );
    } else {
      try {
        apiObject.id
          ? await putCoveragePlanCapacity({
              carrierId: props.carrierId,
              data: apiObject,
            })
          : await postCoveragePlanCapacity({
              carrierId: props.carrierId,
              data: apiObject,
            });

        toast.success("Coverage plan capacity saved successfully.");

        props.reloadCapacities();
        props.onClose();
      } catch (error) {
        console.error(error);

        toast.error("There was an error saving the coverage plan capacity.");
      }
    }
  };

  return (
    <Modal size="large" open onClose={props.onClose}>
      <Modal.Header>
        {props.defaultValues.id ? "Edit" : "Add"} Coverage Plan Capacity -{" "}
        {props.carrierName}
      </Modal.Header>

      <Modal.Content>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        <CarrierCoveragePlanForm
          control={control}
          errors={errors}
          setValue={setValue}
          planId={watch("planId")}
          laneId={watch("laneId")}
          closeForm={props.onClose}
          defaultValues={props.defaultValues}
          coveragePlans={data?.entities ?? []}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button type="button" negative onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          loading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CarrierCoveragePlanFormModal;
