import { FC } from "react";
import { Table, Placeholder } from "semantic-ui-react";

const CapacityLoaderRow = (props: FC) => {
  const { ...rest } = props;
  return (
    <Table.Row {...rest}>
      {Array(10)
        .fill(null)
        .map((_, idx) => (
          <Table.Cell
            key={`${crypto.randomUUID()}_loading_row`}
            style={{ backgroundColor: "white" }}
          >
            <Placeholder fluid>
              <Placeholder.Paragraph>
                <Placeholder.Line length="full" title="placeholder" />
                <Placeholder.Line length="medium" />
              </Placeholder.Paragraph>
            </Placeholder>
          </Table.Cell>
        ))}
    </Table.Row>
  );
};

export { CapacityLoaderRow };
