import { Label, Popup } from "semantic-ui-react";
import momentTz from "moment-timezone";
import styles from "./CapacityList.module.scss";
import { PREFERRED_LANE, CAPACITY, SPOT, NO_CAPACITY } from "shared/constants";

interface CapacityTypeIconProps {
  type: string;
  createdBy: string | null;
  creationDate: string | null;
  notes: string | null;
}

const CapacityTypeIcon = ({
  type,
  createdBy,
  creationDate,
  notes,
}: CapacityTypeIconProps) => {
  const noCapacityStyle = type === NO_CAPACITY ? styles.ncIcon : styles.plIcon;
  const noCapacityText = type === NO_CAPACITY ? "NC" : "PL";
  const noCapacityHeader = type === NO_CAPACITY ? NO_CAPACITY : PREFERRED_LANE;

  const getCapacityContent = () => {
    const popupContent: string[] = [];
    if (createdBy) {
      popupContent.push(`by ${createdBy}`);
    }

    if (creationDate) {
      popupContent.push(
        `at ${momentTz.utc(creationDate).local().format("MM/DD/YY h:mm A")}`,
      );
    }
    return popupContent.length === 0
      ? null
      : `Created ${popupContent.join(" ")}`;
  };

  return (
    <Popup
      className={styles.ml_n9}
      trigger={
        <Label
          className={
            type === SPOT || type === CAPACITY ? styles.cIcon : noCapacityStyle
          }
          circular
        >
          {type === SPOT || type === CAPACITY ? "C " : noCapacityText}
        </Label>
      }
    >
      <Popup.Header>
        {type === SPOT || type === CAPACITY ? CAPACITY : noCapacityHeader}
      </Popup.Header>
      <Popup.Content>
        <div>{getCapacityContent()}</div>
        <div>
          <div className={notes ? styles.notesContent : undefined}>
            {notes && "Notes"}
          </div>
          <div>{notes}</div>
        </div>
      </Popup.Content>
    </Popup>
  );
};

export default CapacityTypeIcon;
