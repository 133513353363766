import { CarrierCoveragePlanCapacityDto } from "../../../../models/dto/CarrierCoveragePlanCapacityDto";
import { CarrierCoveragePlanFormObject } from "../CarrierCoveragePlanForm";

export const mapApiToFormObject = (
  apiCoveragePlan: CarrierCoveragePlanCapacityDto
): CarrierCoveragePlanFormObject => {
  return {
    id: apiCoveragePlan.id?.toString() ?? "",
    planId: apiCoveragePlan.planId,
    laneId: apiCoveragePlan.laneId,
    equipment: apiCoveragePlan.equipment,
    coverageAssigned: apiCoveragePlan.coverageAssigned.toString(),
    rate: apiCoveragePlan.rate?.toString() ?? "",
    note: apiCoveragePlan.note,
  };
};
