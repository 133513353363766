import type { CSSProperties } from "react";
import clsx from "clsx";

import s from "./Skeleton.module.scss";

interface SkeletonProps {
  className?: string;
  rounded?: number | string;
  width?: number | string;
  height?: number | string;
  unEqualWidth?: boolean;
  gap?: number;
  items?: number;
  style?: CSSProperties;
}

export const LoadingSkeleton = ({
  className,
  rounded,
  width = "100%",
  height = 20,
  items = 1,
  gap = 0,
  unEqualWidth,
  style,
}: SkeletonProps) => {
  const arr = Array.from({ length: items });

  return (
    <>
      {arr.map((_, index) => {
        const isLast = index === arr.length - 1;
        const updatedWidth =
          isLast && unEqualWidth ? `calc(${width} * 0.6)` : width;

        return (
          <div
            key={index}
            className={clsx(s.skeleton, className)}
            style={{
              maxWidth: updatedWidth,
              height: height,
              borderRadius: rounded,
              marginBottom: gap,
              ...style,
            }}
          />
        );
      })}
    </>
  );
};
