import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "api/capacityMatchingAPI";
import { formatDate } from "shared/utils/utility";
import { toast } from "react-toastify";

export const modifyDateFormat = (date) => {
  if (date) {
    const dateArr = date.split("/");
    return `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`;
  }
};

export const getCapacityInfo = createAsyncThunk(
  "capacityInfo/get",
  async ({ carrierId, requestBody = {} }) => {
    const { data } = await API.getCapacityInfoByCarrierId(
      carrierId,
      requestBody
    );

    const entities = data.entities.map((item) => {
      const formattedAvailabilityDate = item.availabilityDate
        ? formatDate(item.availabilityDate)
        : null;
        
      const formattedExpirationDate = item.expirationDate
        ? formatDate(item.expirationDate)
        : null;

      return {
        ...item,
        availabilityDate: formattedAvailabilityDate,
        expirationDate: formattedExpirationDate,
      };
    });

    return { ...data, entities };
  }
);

export const getEquipmentOptions = createAsyncThunk(
  "capacityInfo/getEquipmentOptions",
  async () => {
    const { data } = await API.getEquipmentType();

    return data.map((item) => ({
      key: item.id,
      value: item.value,
      text: item.text,
    }));
  }
);

export const createCapacityInfo = createAsyncThunk(
  "capacityInfo/create",
  async ({ carrierId, capacityInfoItem }, thunkAPI) => {
    const { data } = await API.createCapacityInfo(carrierId, {
      ...capacityInfoItem,
      availabilityDate: modifyDateFormat(capacityInfoItem.availabilityDate),
    });

    const addedItem = { ...capacityInfoItem, id: data, isEdit: false };
    thunkAPI.dispatch(
      triggerCapacityMatching({ carrierId, capacityInfoItemId: data })
    );

    return addedItem;
  }
);

export const updateCapacityInfo = createAsyncThunk(
  "capacityInfo/update",
  async ({ carrierId, capacityInfoItem }, thunkAPI) => {
    await API.updateCapacityInfo(carrierId, {
      ...capacityInfoItem,
      availabilityDate: modifyDateFormat(capacityInfoItem.availabilityDate),
      expirationDate: modifyDateFormat(capacityInfoItem.expirationDate),
    });

    thunkAPI.dispatch(getCapacityInfo({ carrierId }));
    thunkAPI.dispatch(
      triggerCapacityMatching({
        carrierId,
        capacityInfoItemId: capacityInfoItem.id,
      })
    );

    return capacityInfoItem;
  }
);

export const deleteCapacityItem = createAsyncThunk(
  "capacityInfo/delete",
  async ({ carrierId, capacityItemId }, thunkAPI) => {
    await API.removeCapacityItem(carrierId, capacityItemId);
    thunkAPI.dispatch(getCapacityInfo({ carrierId }));
  }
);

export const getCapacityMatchingLog = createAsyncThunk(
  "matches/get",
  async ({ carrierId, capacityInfoItemId, requestBody = {} }) => {
    const { data } = await API.getCapacityMatchingLog(
      carrierId,
      capacityInfoItemId,
      requestBody
    );

    return { ...data, capacityInfoItemId };
  }
);

export const triggerCapacityMatching = createAsyncThunk(
  "matches/triggerMatching",
  async ({ carrierId, capacityInfoItemId }) => {
    const { data } = await API.triggerCapacityMatchingAlgo(
      carrierId,
      capacityInfoItemId
    );
    toast.success("Shipment Matching Completed")
    return { ...data, capacityInfoItemId };
  }
);

export const triggerCapacityMatchingAutomate = createAsyncThunk(
  "matches/triggerMatching",
  async ({ carrierId, capacityInfoItemId }) => {
const {data} = await API.triggerCarrierCapacityMatchesAutomate(
  carrierId,
    capacityInfoItemId
)
    return { ...data, capacityInfoItemId };
  }
);

export const setCapacityInfoPageSize =
  ({ pageSize, carrierId }, actionCallback) =>
  (dispatch) => {
    dispatch(
      actionCallback.setPageSize({
        fieldName: "capacityInfo",
        pageSize,
      })
    );
    dispatch(
      getCapacityInfo({ carrierId, requestBody: { page: 1, pageSize } })
    );
  };

export const setCapacityInfoPage =
  ({ page, pageSize, carrierId }, actionCallback) =>
  (dispatch) => {
    dispatch(
      actionCallback.setPage({
        fieldName: "capacityInfo",
        page,
      })
    );
    dispatch(getCapacityInfo({ carrierId, requestBody: { page, pageSize } }));
  };

export const setMatchesPageSize =
  ({ pageSize, carrierId, capacityInfoItemId }, actionCallback) =>
  (dispatch) => {
    dispatch(
      actionCallback.setPageSize({
        fieldName: "matches",
        pageSize,
      })
    );
    dispatch(
      getCapacityMatchingLog({
        carrierId,
        capacityInfoItemId,
        requestBody: { page: 1, pageSize },
      })
    );
  };

export const setMatchesPage =
  ({ page, pageSize, carrierId, capacityInfoItemId }, actionCallback) =>
  (dispatch) => {
    dispatch(
      actionCallback.setPage({
        fieldName: "matches",
        page,
      })
    );
    dispatch(
      getCapacityMatchingLog({
        carrierId,
        capacityInfoItemId,
        requestBody: { page, pageSize },
      })
    );
  };

export const updateActivityNotes = createAsyncThunk(
  "activityNotes/update",
  async ({ noteMessage, carrierId }) => {
    await API.updateActivityNotes({ noteMessage, carrierId });
  }
);
