import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "./thunks";

import { CAPACITY, NO_CAPACITY } from "shared/constants";

const capacityMatchingSlice = createSlice({
  name: "capacityMatching",
  initialState: {
    carriers: {
      selectedCarrier: {},
    },
    capacityInfo: {
      list: [],
      total: 0,
      loading: false,
      capacityId: null,
      capacityLoadingId: null,
      page: 1,
      pageSize: 10,
    },
    matches: {
      list: [],
      total: 0,
      loading: false,
      page: 1,
      pageSize: 10,
    },
    options: {
      equipmentTypes: [],
    },
    activityNotes: {
      loading: false,
    },
  },
  reducers: {
    setSelectedCarrier: (state, action) => {
      state.carriers.selectedCarrier = action.payload;
    },
    addNewCapacityInfo: (state) => {
      const newCapacityInfoItem = {
        id: 0,
        isEdit: true,
        type: CAPACITY,
        originRadius: 50,
      };

      state.capacityInfo.list.unshift(newCapacityInfoItem);
    },
    addNoCapacityInfo: (state) => {
      const newNoCapacityInfoItem = {
        id: 0,
        isEdit: true,
        type: NO_CAPACITY,
        originRadius: 50,
        destinationRadius: 50,
      };

      state.capacityInfo.list.unshift(newNoCapacityInfoItem);
    },
    cancelCapacityItemCreation: (state) => {
      state.capacityInfo.list = [
        ...state.capacityInfo.list.filter((item) => item.id !== 0),
      ];
    },
    setPageSize: (state, action) => {
      const { fieldName, pageSize } = action.payload;
      state[fieldName].pageSize = pageSize;
      state[fieldName].page = 1;
    },
    setPage: (state, action) => {
      const { fieldName, page } = action.payload;
      state[fieldName].page = page;
    },
    receiveNotifications: (state, action) => {
      state.capacityInfo.list = state.capacityInfo.list.map((item) => {
        const notification = action.payload.find(
          (x) => x.profilesCarrierCapacityId === item.id,
        );
        if (notification) {
          return {
            ...item,
            unseenNotificationsCount: notification.unseenNotificationsCount,
          };
        }
        return item;
      });
    },
  },
  extraReducers: {
    [thunks.getCapacityInfo.pending]: (state) => {
      state.capacityInfo.loading = true;
    },
    [thunks.getCapacityInfo.fulfilled]: (state, action) => {
      state.capacityInfo.loading = false;
      state.capacityInfo.list = action.payload.entities;
      state.capacityInfo.total = action.payload.filteredCount;
    },
    [thunks.getCapacityInfo.rejected]: (state) => {
      state.capacityInfo.loading = false;
    },

    [thunks.getEquipmentOptions.fulfilled]: (state, action) => {
      state.options.equipmentTypes = action.payload;
    },

    [thunks.createCapacityInfo.pending]: (state, action) => {
      state.matches.loading = true;
      state.capacityInfo.capacityLoadingId =
        action.meta.arg.capacityInfoItem.id;
    },
    [thunks.createCapacityInfo.fulfilled]: (state, action) => {
      const updatedList = [
        ...state.capacityInfo.list.map((item) =>
          item.id === 0 ? { ...action.payload } : item,
        ),
      ];

      if (updatedList.length > state.capacityInfo.pageSize) {
        updatedList.pop();
      }

      state.capacityInfo.list = updatedList;
      state.capacityInfo.capacityLoadingId = action.payload.id;
      state.capacityInfo.total++;
    },
    [thunks.createCapacityInfo.rejected]: (state) => {
      const updatedList = [
        ...state.capacityInfo.list.filter((item) => item.id !== 0),
      ];
      state.matches.loading = false;
      state.capacityInfo.capacityLoadingId = null;
      state.capacityInfo.list = updatedList;
    },

    [thunks.updateCapacityInfo.pending]: (state, action) => {
      state.matches.loading = true;
      state.capacityInfo.capacityLoadingId =
        action.meta.arg.capacityInfoItem.id;
    },
    [thunks.updateCapacityInfo.fulfilled]: (state, action) => {
      state.capacityInfo.list = [
        ...state.capacityInfo.list.map((item) =>
          item.id === action.payload.id ? { ...action.payload } : item,
        ),
      ];
    },
    [thunks.updateCapacityInfo.rejected]: (state) => {
      state.matches.loading = false;
      state.capacityInfo.capacityLoadingId = false;
    },

    [thunks.deleteCapacityItem.pending]: (state, action) => {
      state.capacityInfo.capacityLoadingId = action.payload;
    },
    [thunks.deleteCapacityItem.fulfilled]: (state) => {
      state.capacityInfo.capacityLoadingId = null;
    },
    [thunks.deleteCapacityItem.rejected]: (state) => {
      state.capacityInfo.capacityLoadingId = null;
    },

    [thunks.getCapacityMatchingLog.pending]: (state, action) => {
      state.matches.loading = true;
      if (action.meta.arg.requestBody?.page > 0) {
        state.matches.page = action.meta.arg.requestBody.page;
      }
      if (action.meta.arg.requestBody?.pageSize > 0) {
        state.matches.pageSize = action.meta.arg.requestBody.pageSize;
      }
    },
    [thunks.getCapacityMatchingLog.fulfilled]: (state, action) => {
      state.matches.loading = false;
      state.matches.list = action.payload.entities;
      state.matches.total = action.payload.filteredCount;

      state.capacityInfo.list = [
        ...state.capacityInfo.list.map((capacityItem) =>
          capacityItem.id === action.payload.capacityInfoItemId
            ? { ...capacityItem, matches: action.payload.filteredCount }
            : capacityItem,
        ),
      ];
      state.capacityInfo.capacityLoadingId = null;
    },
    [thunks.getCapacityMatchingLog.rejected]: (state) => {
      state.matches.loading = false;
      state.capacityInfo.capacityLoadingId = null;
    },

    [thunks.triggerCapacityMatching.pending]: (state) => {
      state.matches.loading = true;
      state.matches.page = 1;
      state.matches.pageSize = 10;
    },
    [thunks.triggerCapacityMatchingAutomate.pending]: (state) => {
      state.matches.loading = true;
      state.matches.page = 1;
      state.matches.pageSize = 10;
    },
    [thunks.triggerCapacityMatchingAutomate.fulfilled]: (state, action) => {
      state.matches.loading = false;
      state.matches.list = action.payload.entities;
      state.matches.total = action.payload.filteredCount;
    },
    [thunks.triggerCapacityMatching.fulfilled]: (state, action) => {
      state.matches.loading = false;
      state.matches.list = action.payload.entities;
      state.matches.total = action.payload.filteredCount;

      state.capacityInfo.list = [
        ...state.capacityInfo.list.map((capacityItem) =>
          capacityItem.id === action.payload.capacityInfoItemId
            ? { ...capacityItem, matches: action.payload.filteredCount }
            : capacityItem,
        ),
      ];
      state.capacityInfo.capacityLoadingId = null;
    },
    [thunks.triggerCapacityMatching.rejected]: state => {
      state.matches.loading = false;
      state.capacityInfo.capacityLoadingId = null;
    },
    [thunks.triggerCapacityMatchingAutomate.rejected]: state => {
      state.matches.loading = false;
      state.capacityInfo.capacityLoadingId = null;
    },

    [thunks.updateActivityNotes.pending]: (state, action) => {
      state.activityNotes.loading = action.meta.arg.noteType;
    },
    [thunks.updateActivityNotes.fulfilled]: state => {
      state.activityNotes.loading = null;
    },
    [thunks.updateActivityNotes.rejected]: state => {
      state.activityNotes.loading = null;
    },
  },
});

export const actions = {
  ...capacityMatchingSlice.actions,
  ...thunks,
  setCapacityInfoPageSize: (payload) =>
    thunks.setCapacityInfoPageSize(payload, {
      setPageSize: capacityMatchingSlice.actions.setPageSize,
    }),
  setCapacityInfoPage: (payload) =>
    thunks.setCapacityInfoPage(payload, {
      setPage: capacityMatchingSlice.actions.setPage,
    }),
  setMatchesPageSize: (payload) =>
    thunks.setMatchesPageSize(payload, {
      setPageSize: capacityMatchingSlice.actions.setPageSize,
    }),
  setMatchesPage: (payload) =>
    thunks.setMatchesPage(payload, {
      setPage: capacityMatchingSlice.actions.setPage,
    }),
};

export const reducer = capacityMatchingSlice.reducer;
