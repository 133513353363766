import { useState } from "react";
import Pagination from "../../_shared/Pagination";
import { Confirm } from "semantic-ui-react";

import { CapacityTable } from "./Table/CapTable";
import { CapacityListHeader } from "components/CapacityMatchingSegment/CapacityList/Table/CapacityListHeader";
import CapacityCoveragePlans from "./Table/CapacityCoveragePlans";
import { CarrierCapacityInfoDto } from "../../../models/dto/CarrierCapacityInfoDto";

import styles from "./CapacityList.module.scss";

export type CapacityTabName = "Capacity" | "CoveragePlans";

type CapacityListProps = {
  data: CarrierCapacityInfoDto[];
  loading: boolean;
  isEditing: boolean;
  mode: string;
  notesLoading: boolean;
  carrier: { id: number; name: string };
  selectedItemId: string | null;
  loadingRowId: string | null;
  tableActions: {
    onAddNew: () => void;
    onAddNoCap: () => void;
    onRowUpdate: (row: CarrierCapacityInfoDto) => void;
    onRowCreationCancel: () => void;
    onRowDelete: (id: number) => void;
    onCapacityMatchesSelect: (id: number) => void;
    onCapacityExpire: (row: CarrierCapacityInfoDto) => void;
    onStartEdit: () => void;
    onFinishEdit: () => void;
    onNotesChange: (noteMessage: string, noteType: string) => void;
    onClose: () => void;
  };
  pagination: {
    onPageSizeChange: (pageSize: number) => void;
    onPageChange: (page: number) => void;
    total: number;
    pageSize: number;
    page: number;
  };
};

const CapacityList = (props: CapacityListProps) => {
  const [expItem, setExpItem] = useState<CarrierCapacityInfoDto | null>(null);
  const [tabName, setTabName] = useState<CapacityTabName>("Capacity");
  const [isAddingPlanCapacity, setIsAddingPlanCapacity] = useState(false);

  return (
    <>
      <div className={styles.dataContainer}>
        <CapacityListHeader
          isEditing={props.isEditing}
          mode={props.mode}
          notesLoading={props.notesLoading}
          onAddNew={props.tableActions.onAddNew}
          onNotesChange={props.tableActions.onNotesChange}
          onAddNewNoCapacity={props.tableActions.onAddNoCap}
          onClose={props.tableActions.onClose}
          loading={props.loading}
          tabName={tabName}
          setTabName={setTabName}
          onAddNewPlanCapacity={() => setIsAddingPlanCapacity(true)}
        />

        {(() => {
          switch (tabName) {
            case "Capacity":
              return (
                <>
                  <CapacityTable
                    data={props.data}
                    loading={props.loading}
                    isEditing={props.isEditing}
                    mode={props.mode}
                    notesLoading={props.notesLoading}
                    carrier={props.carrier}
                    selectedItemId={props.selectedItemId}
                    loadingRowId={props.loadingRowId}
                    tableActions={{
                      ...props.tableActions,
                      onCapacityExpire: setExpItem,
                    }}
                  />
                  <Pagination
                    onPageSizeChange={props.pagination.onPageSizeChange}
                    onPageChange={props.pagination.onPageChange}
                    filteredCount={props.pagination.total}
                    pageSize={props.pagination.pageSize}
                    page={props.pagination.page}
                    halfPadding
                  />
                </>
              );
            case "CoveragePlans":
              return (
                <CapacityCoveragePlans
                  carrierId={props.carrier.id}
                  carrierName={props.carrier?.name ?? ""}
                  isAddingPlanCapacity={isAddingPlanCapacity}
                  closeAddingPlanCapacityModal={() =>
                    setIsAddingPlanCapacity(false)
                  }
                />
              );
            default:
              return null;
          }
        })()}
      </div>

      <Confirm
        content="Do you want to expire the capacity?"
        open={!!expItem}
        onCancel={() => setExpItem(null)}
        onConfirm={() => {
          setExpItem(null);
          if (expItem) {
            props.tableActions.onCapacityExpire(expItem);
          }
        }}
      />
    </>
  );
};

export default CapacityList;
