import { useState, useEffect, useCallback } from "react";
import { Table, Icon, Dropdown, Form, Input, Button, TextArea, Checkbox } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import moment from "moment";
import { getLocations } from "api/capacityMatchingAPI";
import { TypeDropdown } from "./components/TypeDropdown";
import { AvailDatepicker } from "./components/AvailDatepicker";
import {
  PREFERRED_LANE,
  STATIC,
  NO_CAPACITY,
  capacityTypeOptions,
  milesOptions,
  dayOfWeekOptions,
} from "shared/constants";
import { LocationSearchDropdown } from "components/CapacityMatchingSegment/CapacityList/Table/components/SearchDropdown";

const CapacityWriteRow = (props) => {
  const { item, equipmentTypeOptions, onSave, onCancel, ...rest } = props;
  const [data, setData] = useState(item);
  const [selectedDays, setSelectedDays] = useState([]);
  const { register, unregister, errors, setValue, trigger } = useForm();
  const _isPrefferedLane = () =>
    data.type === STATIC || data.type === PREFERRED_LANE;

  const _isNonCapacity = useCallback(() => {
    return data.type === NO_CAPACITY;
  }, [data]);

  const _handleCancel = () => {
    onCancel();
  };
  const _handleChange = (name, value, options) => {
    if (name === "type" && value === "Preferred Lane") {
      setSelectedDays([]);
      setData({
        ...data,
        [name]: value,
        expirationDate: "",
        availabilityDate: "",
        staticDays: "",
      });
    } else {
      setData({ ...data, [name]: value });
    }

    if (options?.validate) {
      setValue(name, value);
      _triggerValidation(name);
    }
  };

  const convertToDate = (dateValue) => {
    return moment(dateValue, "MM/DD/YYYY", true);
  };
  const validateDate = (dateValue) => {
    return convertToDate(dateValue).isValid();
  };

  const _handleDateRangeChange = (value, options) => {
    let availDate = value.includes("-") ? value.split("-")[0].trim() : "";
    let expDate = value.includes("-") ? value.split("-")[1].trim() : "";

    if (
      validateDate(availDate) &&
      validateDate(expDate) &&
      convertToDate(availDate) >= convertToDate(expDate)
    ) {
      return;
    }

    setData({
      ...data,
      availabilityDate: validateDate(availDate) ? availDate : "",
      expirationDate: validateDate(expDate) ? expDate : "",
    });

    if (options?.validate) {
      setValue("availabilityDate", validateDate(availDate) ? availDate : "");
      _triggerValidation("availabilityDate");
      setValue("expirationDate", validateDate(expDate) ? expDate : "");
      _triggerValidation("expirationDate");
    }
  };

  const _handleDestinationChange = (val) => {
    const validate = _isNonCapacity() ? _isNonCapacity() : _isPrefferedLane();
    if (!data.destinationRadius) {
      _handleChange("destinationRadius", null, { validate: validate });
    }

    _handleChange("destination", val ?? null, { validate: validate });
    if (data.id !== 0 || data.destination) {
      _handleChange("destinationRadius", data.destinationRadius ?? null, {
        validate: !!data.destination,
      });
    }
  };

  const loadAvailExpDate = () => {
    let availExpDate;
    if (data.availabilityDate && data.expirationDate) {
      availExpDate = `${data.availabilityDate} - ${data.expirationDate}`;
    } else if (data.availabilityDate && !data.expirationDate) {
      availExpDate = `${data.availabilityDate} -`;
    } else {
      availExpDate = "";
    }
    return availExpDate;
  };

  const _handleSave = () => {
    const staticDays = dayOfWeekOptions
      .filter((day) => selectedDays.includes(day.value))
      .map((val) => val.value)
      .join(",");
    onSave({ ...data, staticDays: staticDays });
  };

  useEffect(() => {
    let data;
    if (item.id === 0) {
      data = dayOfWeekOptions.map((day) => day.value);
    } else {
      data = item.staticDays ? item.staticDays.split(",") : [];
    }
    if (!_isNonCapacity()) setSelectedDays(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const _triggerValidation = (data) => {
    (async () => {
      await trigger(data).then().catch((e) => {
        console.error('Error in trigger', e);
      });
    })();
  };

  useEffect(() => {
    const registered = [];
    if (_isPrefferedLane()) {
      registered.push("destination", "destinationRadius");
    }
    else if (_isNonCapacity()) {
      registered.push("destination", "destinationRadius");
    }
    else {
      registered.push("availabilityDate", "expirationDate", "rate");
    }

    registered.push("origin", "originRadius", "equipmentModeCategoryId");
    registered.forEach((itemName) => {
      register(itemName, { required: true });
      setValue(itemName, data[itemName]);
    });
    _triggerValidation(registered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const registered = [];
    const unregistered = [];

    if (_isPrefferedLane() || _isNonCapacity()) {
      registered.push("destination", "destinationRadius");
      unregistered.push("rate", "availabilityDate", "expirationDate");
    }
     else {
      registered.push("rate", "availabilityDate", "expirationDate");
      if (data.destination) {
        registered.push("destinationRadius");
      } else {
        unregistered.push("destination");
        unregistered.push("destinationRadius");
      }
    }
    registered.forEach((item) => {
      register(item, { required: true });
      setValue(item, data[item]);
    });
    unregistered.forEach((item) => {
      unregister(item);
    });
    _triggerValidation([...registered, ...unregistered]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.type, data.destination]);

  return (
    <Table.Row {...rest}>
      <TableCellStyled colSpan="12">
        <Form>
          <Form.Group widths="equal">
            <Form.Field required width={1} autoFocus>
              <label>Type</label>
              <TypeDropdown
                options={_isNonCapacity() ? capacityTypeOptions : capacityTypeOptions.filter(({value})=> value !== NO_CAPACITY)}
                value={data.type || ''}
                disabled={_isNonCapacity()}
                placeholder="Type"
                onChange={(_, { value }) => _handleChange("type", value)}
                selection
                autoFocus={!_isNonCapacity}
                fluid
              />
            </Form.Field>

            <Form.Field
              required={!_isPrefferedLane() && !_isNonCapacity()}
              width={2}
              error={!!errors.availabilityDate || !!errors.expirationDate}
            >
              <label>Avail Date - Exp Date</label>
              <AvailDatepicker
                value={loadAvailExpDate()}
                disabled={_isPrefferedLane() || _isNonCapacity()}
                onChange={({ value }) => {
                  _handleDateRangeChange(value, {
                    validate: !_isPrefferedLane() && !_isNonCapacity(),
                  });
                }}
                minDate={new Date()}
                title="Avail Date - Exp Date"
              />
            </Form.Field>

            <Form.Field
               required={_isNonCapacity() ? !_isNonCapacity() : !_isPrefferedLane()}
              width={1}
              error={!!errors.rate}
            >
              <label>Rate</label>
              <Input
                name="rate"
                label={{ basic: true, content: "$" }}
                labelPosition="left"
                placeholder="$ rate"
                value={data.rate || ""}
                onChange={(e) => {
                  _handleChange("rate", e.target.value.replace(/\D+/, ""), {
                    validate: !_isNonCapacity ? !_isNonCapacity() : !_isPrefferedLane(),
                  });
                }}
              />
            </Form.Field>

            <Form.Field
              required
              width={2}
              error={!!errors.equipmentModeCategoryId}
            >
              <label>Equip. Type</label>
              <Dropdown
                options={equipmentTypeOptions}
                value={data.equipmentModeCategoryId || ""}
                onChange={(_, { value }) =>
                  _handleChange("equipmentModeCategoryId", value, {
                    validate: true,
                  })
                }
                placeholder="Equipment"
                search
                selection
                fluid
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required width={5} error={!!errors.origin}>
              <label>Origin</label>
              <LocationSearchDropdown
                value={data.origin || ""}
                searchFunction={getLocations}
                onChange={(val) => {
                  _handleChange("origin", val ?? null, { validate: true });
                }}
              />
            </Form.Field>

            <Form.Field required width={1} error={!!errors.originRadius}>
              <label>/radius</label>
              <Dropdown
                options={milesOptions}
                value={data.originRadius || ''}
                placeholder="mi."
                onChange={(_, { value }) =>
                  _handleChange("originRadius", value, { validate: true })
                }
                disabled={!data.origin}
                selection
                fluid
              />
            </Form.Field>

            <Form.Field
              required={_isNonCapacity() ? _isNonCapacity() : _isPrefferedLane()}
              width={5}
              error={!!errors.destination}
            >
              <label>Destination</label>
              <LocationSearchDropdown
                value={data.destination || ""}
                searchFunction={getLocations}
                onChange={_handleDestinationChange}
              />
            </Form.Field>

            <Form.Field
              required={!!data.destination}
              width={1}
              error={!!errors.destinationRadius}
            >
              <label>/radius</label>
              <Dropdown
                options={milesOptions}
                value={data.destinationRadius || ''}
                placeholder="mi."
                onChange={(_, { value }) =>
                  _handleChange("destinationRadius", value, { validate: true })
                }
                disabled={!data.destination}
                selection
                fluid
              />
            </Form.Field>            
          </Form.Group>
          <Form.Group>
            <Form.Field width={8}>
              <label>Notes</label>
              <TextArea
                name="notes"
                placeholder="Notes"
                value={data.notes ? data.notes : ''}
                onChange={(e) => _handleChange('notes', e.target.value)}
              />
            </Form.Field>
            <Form.Field
              width={8}
            >
              <label>Weekly availability</label>
              <DayOfWeekCheckboxGroup>
                {dayOfWeekOptions.map((day) => (
                  <Checkbox
                    key={day.key}
                    label={day.text}
                    name={day.value}
                    checked={selectedDays.includes(day.value)}
                    disabled={_isPrefferedLane() || _isNonCapacity()}
                    onChange={(e, data) => {
                      selectedDays.includes(data.name)
                        ? setSelectedDays((prev) =>
                          prev.filter((item) => item !== data.name)
                        )
                        : setSelectedDays((prev) => [...prev, data.name]);
                    }}
                  />
                ))}
              </DayOfWeekCheckboxGroup>
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <Button
                onClick={_handleSave}
                size="tiny"
                icon
                labelPosition="right"
                primary
                disabled={Object.keys(errors).length > 0}
              >
                <Icon name="check" />
                Save
              </Button>
            </Form.Field>
            <Form.Field>
              <Button
                size="tiny"
                icon
                labelPosition="right"
                basic
                onClick={_handleCancel}
              >
                <Icon name="close" />
                Cancel
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </TableCellStyled>
    </Table.Row>
  );
};

export { CapacityWriteRow };

const TableCellStyled = styled(Table.Cell)`
  background-color: white;
  &:hover {
    background-color: white;
  }
`;

const DayOfWeekCheckboxGroup = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  div {
    flex: 0 30%;
  }
`;