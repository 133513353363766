import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AttributeGroup } from "../../models/AttributeGroup";
import * as API from "../../api/locationAPI";

export const fetchLocationAttributes = createAsyncThunk<
  AttributeGroup[],
  void,
  { rejectValue: string }
>("locationAttributes/fetch", async (_, { rejectWithValue }) => {
  try {
    const response = await API.getLocationAttributes();
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export const updateLocationAttributes = createAsyncThunk<
  void,
  {
    locationId: number;
    attributeList: { profilesAttributeId: number; value: unknown }[];
  },
  { rejectValue: string }
>(
  "locationAttributes/update",
  async ({ locationId, attributeList }, { rejectWithValue }) => {
    try {
      await API.updateLocationAttributes({ locationId, attributeList });
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  }
);

export interface LocationAttributeState {
  list: AttributeGroup[];
  loading: boolean;
  error: string | undefined;
}

export const initialState: LocationAttributeState = {
  list: [],
  loading: false,
  error: undefined,
};

export const locationAttributesSlice = createSlice({
  name: "locationAttributes",
  initialState,
  reducers: {
    resetAttributes: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocationAttributes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLocationAttributes.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
        if (action.payload.length === 0) {
          state.error = "No Attributes were found";
        }
      })
      .addCase(fetchLocationAttributes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateLocationAttributes.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLocationAttributes.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateLocationAttributes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message
      });
  },
});

export const { resetAttributes } = locationAttributesSlice.actions;

export default locationAttributesSlice.reducer;
