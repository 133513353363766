import { Image } from "semantic-ui-react";
import styles from "./MarketplaceIcon.module.scss";

import activeLogo from "./options/MPIconActive.png";
import registeredLogo from "./options/MPIconReg.png";
import invitedLogo from "./options/MPIconInvited.png";

interface LogoStates {
  active: string;
  registered: string;
  invited: string;
  [key: string]: string;
}

const logoStates: LogoStates = {
  active: activeLogo,
  registered: registeredLogo,
  invited: invitedLogo,
};

const sizes = {
  small: styles.mini,
  big: styles.full,
};

interface MarketplaceIconProps {
  size?: "small" | "big";
  state: string | null;
}

export const MarketplaceIcon = (props: MarketplaceIconProps) => {
  const { size = "big", state = null } = props;

  if (state === null) {
    return null;
  }

  return (
    <div className={sizes[size]}>
      <Image src={logoStates[state]} data-testid="marketplace-icon" />
    </div>
  );
};
