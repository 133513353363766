import * as actionTypes from '../constants/actionTypes';

const initialState = {
    states: [],
    loading: false,
    repsLoading: false,
    reps: [],
    vehiclesLoading: false,
    vehicles: [],
    optionsLoading: false,
    optionsData: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_MAP_STATS_START: return { ...state, loading: true };
        case actionTypes.FETCH_MAP_STATS_SUCCESS: return { ...state, states: action.payload, loading: false };
        case actionTypes.FETCH_MAP_STATS_ERROR: return { ...state, loading: false };
        case actionTypes.FETCH_REPS_STATS_START: return { ...state, repsLoading: true };
        case actionTypes.FETCH_REPS_STATS_SUCCESS: return { ...state, reps: action.payload, repsLoading: false };
        case actionTypes.FETCH_REPS_STATS_ERROR: return { ...state, repsLoading: false };
        case actionTypes.FETCH_VEHICLES_STATS_START: return { ...state, vehiclesLoading: true };
        case actionTypes.FETCH_VEHICLES_STATS_SUCCESS: return { ...state, vehicles: action.payload, vehiclesLoading: false };
        case actionTypes.FETCH_VEHICLES_STATS_ERROR: return { ...state, vehiclesLoading: false };
        case actionTypes.FETCH_MAP_OPTIONS_START: return { ...state, optionsLoading: true };
        case actionTypes.FETCH_MAP_OPTIONS_SUCCESS: return { ...state, optionsLoading: false, optionsData: action.payload };
        case actionTypes.FETCH_MAP_OPTIONS_ERROR: return { ...state, optionsLoading: false };

        default: return state;
    }
};

export default reducer;
