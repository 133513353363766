import { useEffect } from "react";

import ListBody from "./ListBody";
import ListHeader from "./ListHeader";
import Pagination from "components/_shared/Pagination";

import styles from "../CarrierList.module.scss";

const List = (props) => {
  useEffect(() => {
    let element = document.getElementById(
      `list_row_${props.selectedCarrier?.id}`
    );

    if (!element) {
      let containerElement = document.getElementById("list-data-container");

      if (containerElement) {
        containerElement.scrollTop = props.scrollTop;
      }

      return;
    }

    element.scrollIntoView();
    props.onResetSelectedCarrier();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.carriers.entities]);

  const handleScroll = (e) => {
    props.onSetScroll(e.target.scrollTop);
  };

  return (
    <>
      <div className={styles.CarrierContainerHeader}>
        <ListHeader />
      </div>

      <div
        id="list-data-container"
        className={styles.DataContainer}
        onScroll={handleScroll}
      >
        <ListBody
          onRowClick={props.onRowClick}
          carriersLoading={props.carriersLoading}
          carrierData={props.carriers?.entities}
          onEditCarrier={props.onEditCarrier}
          user={props.user}
          selectedCarrier={props.selectedCarrier}
        />
      </div>

      <Pagination
        onPageSizeChange={(pageSize) =>
          props.handlePageSizeChange(null, { value: pageSize })
        }
        onPageChange={(page) => {
          props.handlePageChange(null, { activePage: page });
        }}
        filteredCount={props.carriers?.filteredCount}
        pageSize={props.gridFilter?.pageSize}
        page={props.gridFilter?.page}
      />
    </>
  );
};

export default List;
