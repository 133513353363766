import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "api/carrierRateAPI";

interface CarrierRateRequest {
  page?: number;
  pageSize?: number;
}

export const getCarrierRateOptions = createAsyncThunk(
  "carrierRateOptions/get",
  async () => {
    const { data } = await API.getCarrierRateOptions();

    return { ...data };
  }
);

export const getCarrierRateList = createAsyncThunk(
  "carrierRate/get",
  async (
    {
      carrierId,
      requestBody,
    }: { carrierId: string; requestBody: CarrierRateRequest }
  ) => {
    const { data } = await API.getCarrierRateList(carrierId, requestBody);

    return { ...data };
  }
);

export const createCarrierRateInfo = createAsyncThunk(
  "carrierRate/create",
  async ({ carrierId }: {carrierId: string}, thunkAPI) => {
    // await API.createCarrierRateInfo({ ...request });
    thunkAPI.dispatch(
      getCarrierRateList({ carrierId, requestBody: { page: 1, pageSize: 10 } })
    );
  }
);

export const updateCarrierRateInfo = createAsyncThunk(
  "carrierRate/update",
  async ({ carrierId }: {carrierId: string}, thunkAPI) => {
    // await API.updateCarrierRateInfo({ ...request });
    thunkAPI.dispatch(
      getCarrierRateList({ carrierId, requestBody: { page: 1, pageSize: 10 } })
    );
  }
);

export const deleteCarrierRateItem = createAsyncThunk(
  "carrierRate/delete",
  async (
    { carrierId, carrierRateId }: { carrierId: string; carrierRateId: number },
    thunkAPI
  ) => {
    await API.removeCarrierRateItem(carrierRateId);
    thunkAPI.dispatch(
      getCarrierRateList({ carrierId, requestBody: { page: 1, pageSize: 10 } })
    );
  }
);
