import { useState } from "react";
import { KeyedMutator } from "swr";
import { Icon, Loader, Table } from "semantic-ui-react";

import { LoadingSkeleton } from "components/LoadingSkeleton/LoadingSkeleton";
import { CarrierCoveragePlanCapacityDto } from "../../../../models/dto/CarrierCoveragePlanCapacityDto";
import { useGetCoveragePlan } from "pages/CoveragePlans/CoveragePlanForm/api/hooks/useGetCoveragePlan";
import CarrierCoveragePlanFormModal from "pages/CoveragePlans/CarrierCoveragePlanForm/CarrierCoveragePlanFormModal";
import { mapApiToFormObject } from "pages/CoveragePlans/CarrierCoveragePlanForm/utils/coveragePlanCapacityApiToFormMappers";
import { QueryGridResponse } from "../../../../models/QueryGridResponse";

type CapacityCoveragePlanRowProps = {
  planCapacity: CarrierCoveragePlanCapacityDto;
  carrierId: number;
  carrierName: string;
  reloadCapacities: KeyedMutator<
    QueryGridResponse<CarrierCoveragePlanCapacityDto>
  >;
};

const formatCurrency = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(value);

const CapacityCoveragePlanRow = (props: CapacityCoveragePlanRowProps) => {
  const [isEditingCapacity, setIsEditingCapacity] = useState(false);
  const { data } = useGetCoveragePlan(props.planCapacity.planId);

  const lane = data?.lanes.find(
    (lane) => lane.id === props.planCapacity.laneId
  );

  const isEditEnabled = data?.status === "Active";

  return data && lane ? (
    <Table.Row>
      <Table.Cell>
        <Icon
          name="edit"
          title={isEditEnabled ? "Edit Capacity" : "Plan is Inactive"}
          link={isEditEnabled}
          disabled={!isEditEnabled}
          onClick={() => setIsEditingCapacity(true)}
        />
      </Table.Cell>
      <Table.Cell>{data.name}</Table.Cell>
      <Table.Cell>{`${lane.origin} → ${lane.destination}`}</Table.Cell>
      <Table.Cell>{props.planCapacity.equipment.name}</Table.Cell>
      <Table.Cell>{`${props.planCapacity.coverageAssigned} / ${data.frequency}`}</Table.Cell>
      <Table.Cell>
        {props.planCapacity.rate
          ? formatCurrency(props.planCapacity.rate)
          : "N/A"}
      </Table.Cell>
      <Table.Cell title={props.planCapacity.note}>
        {props.planCapacity.note || "N/A"}
      </Table.Cell>
      <Table.Cell>{props.planCapacity.lastModifiedUser}</Table.Cell>

      {isEditingCapacity ? (
        <CarrierCoveragePlanFormModal
          onClose={() => setIsEditingCapacity(false)}
          defaultValues={mapApiToFormObject(props.planCapacity)}
          carrierId={props.carrierId}
          carrierName={props.carrierName}
          reloadCapacities={props.reloadCapacities}
        />
      ) : null}
    </Table.Row>
  ) : (
    <Table.Row>
      <Table.Cell>
        <Loader active inline size="mini" />
      </Table.Cell>
      <Table.Cell>
        <LoadingSkeleton />
      </Table.Cell>
      <Table.Cell>
        <LoadingSkeleton />
      </Table.Cell>
      <Table.Cell>
        <LoadingSkeleton />
      </Table.Cell>
      <Table.Cell>
        <LoadingSkeleton />
      </Table.Cell>
      <Table.Cell>
        <LoadingSkeleton />
      </Table.Cell>
      <Table.Cell className="capacity-truncate">
        <LoadingSkeleton />
      </Table.Cell>
      <Table.Cell>
        <LoadingSkeleton />
      </Table.Cell>
    </Table.Row>
  );
};

export default CapacityCoveragePlanRow;
