import styled from "styled-components";
import { MatchesTable } from "./Table/MatchesTable";
import Pagination from "components/_shared/Pagination";

interface MatchesListProps {
  onPageSizeChange: (pageSize: number) => void;
  onPageChange: (page: number) => void;
  total: number;
  pageSize: number;
  page: number;
  data: {
    destination: string;
    equipment: string;
    hasUnseenNotifications: boolean;
    id: number;
    matches: number;
    origin: string;
    pickupDate: string;
    rate: number;
    turvoShipmentId: string;
    bids: number;
  }[];
  loading: boolean;
  onCapacityInfoRefresh: () => void;
  selectedCapacityId: number;
  onClose: () => void;
}

const MatchesList = (props: MatchesListProps) => {
  const {
    onPageSizeChange,
    onPageChange,
    total,
    pageSize,
    page,
    data,
    loading,
    onCapacityInfoRefresh,
    selectedCapacityId,
    onClose,
  } = props;

  return (
    <ListContainerStyled>
      <MatchesTable
        data={data}
        loading={loading}
        onCapacityInfoRefresh={onCapacityInfoRefresh}
        selectedCapacityId={selectedCapacityId}
        onClose={onClose}
      />
      <Pagination
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        filteredCount={total}
        pageSize={pageSize}
        page={page}
        halfPadding
      />
    </ListContainerStyled>
  );
};

export { MatchesList };

const ListContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;
