import { useCallback, useMemo, Fragment } from "react";

import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

import styles from "./Breadcrumbs.module.scss";

interface CrumbsObj {
  [key: string]: JSX.Element[];
}

export const createBreadcrumbs = (
  handleRedirect: (path: string) => void
): CrumbsObj => ({
  "/carriers": [<span key={uuidv4()}>Carriers</span>],
  "/carriers/[0-9]+": [
    <button
      key={uuidv4()}
      className={styles.breadcrumbsLink}
      onClick={() => handleRedirect("/carriers")}
    >
      Carriers
    </button>,
    <span key={uuidv4()}>Carrier Edit</span>,
  ],
  "/customers": [<span key={uuidv4()}>Customers</span>],
  "/customers/[0-9]+": [
    <button
      key={uuidv4()}
      className={styles.breadcrumbsLink}
      onClick={() => handleRedirect("/customers")}
    >
      Customers
    </button>,
    <span key={uuidv4()}>Customer Edit</span>,
  ],
  "/map": [<span key={uuidv4()}>Map</span>],
  "/locations": [<span key={uuidv4()}>Locations</span>],
  "/locations/[0-9]+": [
    <button
      key={uuidv4()}
      className={styles.breadcrumbsLink}
      onClick={() => handleRedirect("/locations")}
    >
      Locations
    </button>,
    <span key={uuidv4()}>Location Edit</span>,
  ],
  "/users": [<span key={uuidv4()}>Users</span>],
  "/users/[0-9]+": [
    <button
      key={uuidv4()}
      className={styles.breadcrumbsLink}
      onClick={() => handleRedirect("/users")}
    >
      Users
    </button>,
    <span key={uuidv4()}>User Edit</span>,
  ],
  "/search/.+": [<span key={uuidv4()}>Global search</span>],
  "/marketplace-carriers": [<span key={uuidv4()}>Marketplace Carriers</span>],
  "/routing-guide": [<span key={uuidv4()}>Routing Guide</span>],
  "/routing-guide/details/[0-9]+": [
    <button
      key={uuidv4()}
      className={styles.breadcrumbsLink}
      onClick={() => handleRedirect("/routing-guide")}
    >
      Routing Guide
    </button>,
    <span key={uuidv4()}>Details</span>,
  ],
  "/coverage-plans": [<span key="coverage-plans">Coverage Plans</span>],
});

const Breadcrumbs = () => {
  const navigate = useNavigate();

  const handleRedirect = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const breadcrumbs = useMemo(() => {
    const crumbsObj = createBreadcrumbs(handleRedirect);
    return Object.keys(crumbsObj).reduce<JSX.Element[]>((acc, pattern) => {
      if (new RegExp(pattern).test(window.location.pathname)) {
        return crumbsObj[pattern];
      }
      return acc;
    }, []);
  }, [handleRedirect]);

  return (
    <div className={styles.breadcrumbsContainer}>
      <button
        className={styles.breadcrumbsLink}
        onClick={() => handleRedirect("/dashboard")}
      >
        Dashboard
      </button>
      {breadcrumbs.map((el) => (
        <Fragment key={uuidv4()}>
          <span key={uuidv4()} className={styles.divider}>
            {">"}
          </span>
          {el}
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumbs;
