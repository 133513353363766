import axios from 'axios';
import * as actionTypes from '../constants/actionTypes';

const fetchCarrierMapStatsStart = () => { return { type: actionTypes.FETCH_MAP_STATS_START }; }
const fetchCarrierMapStatsSuccess = (payload) => { return { type: actionTypes.FETCH_MAP_STATS_SUCCESS, payload }; }
const fetchCarrierMapStatsError = () => { return { type: actionTypes.FETCH_MAP_STATS_ERROR }; }

export const getMapStats = (filter) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchCarrierMapStatsStart());
      axios.post('/api/map/MapStats', filter)
        .then((response) => {
          dispatch(fetchCarrierMapStatsSuccess(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(fetchCarrierMapStatsError());
          reject(error);
        });
    });
  }
};

const fetchRepsStatsStart = () => ({ type: actionTypes.FETCH_REPS_STATS_START });
const fetchRepsStatsSuccess = (payload) => ({ type: actionTypes.FETCH_REPS_STATS_SUCCESS, payload });
const fetchRepsStatsError = () => ({ type: actionTypes.FETCH_REPS_STATS_ERROR });

export const getRepsStats = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchRepsStatsStart());
      axios.get('/api/map/RepsStats')
          .then((response) => {
            dispatch(fetchRepsStatsSuccess(response.data));
            resolve(response.data);
          })
          .catch((error) => {
            dispatch(fetchRepsStatsError());
            reject(error);
          });
    });
  }
};

const fetchVehicleStatsStart = () => ({ type: actionTypes.FETCH_VEHICLES_STATS_START });
const fetchVehicleStatsSuccess = (payload) => ({ type: actionTypes.FETCH_VEHICLES_STATS_SUCCESS, payload });
const fetchVehicleStatsError = () => ({ type: actionTypes.FETCH_VEHICLES_STATS_ERROR });

export const getVehicleStats = (filter) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(fetchVehicleStatsStart());
      axios.post('/api/map/VehicleStats', filter)
          .then((response) => {
            dispatch(fetchVehicleStatsSuccess(response.data));
            resolve(response.data);
          })
          .catch((error) => {
            dispatch(fetchVehicleStatsError());
            reject(error);
          });
    });
  }
};

const fetchMapOptionsStart = () => ({ type: actionTypes.FETCH_MAP_OPTIONS_START });
const fetchMapOptionsSuccess = (payload) => ({ type: actionTypes.FETCH_MAP_OPTIONS_SUCCESS, payload });
const fetchMapOptionsError = () => ({ type: actionTypes.FETCH_MAP_OPTIONS_ERROR });

export const getMapOptionsStats = () => {
  return dispatch => {
    dispatch(fetchMapOptionsStart());
    return new Promise((resolve, reject) => {
      axios.get('/api/map/options')
          .then((response) => {
            dispatch(fetchMapOptionsSuccess(response.data));
            resolve(response.data);
          })
          .catch((error) => {
            dispatch(fetchMapOptionsError());
            reject(error);
          });
    });
  }
};