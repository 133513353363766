import { Button } from "semantic-ui-react";
import { Dispatch, SetStateAction } from "react";

import { CapacityTabName } from "..";

import styles from "./CapacityListHeader.module.scss";

interface CapacityListHeaderProps {
  isEditing: boolean;
  mode: string;
  notesLoading: boolean;
  onAddNew: () => void;
  onNotesChange: (note: string, loading: string) => void;
  onClose: () => void;
  onAddNewNoCapacity: () => void;
  loading: boolean;
  tabName: CapacityTabName;
  setTabName: Dispatch<SetStateAction<CapacityTabName>>;
  onAddNewPlanCapacity: () => void;
}

export const CapacityListHeader = (props: CapacityListHeaderProps) => {
  return !props.loading ? (
    <div className={styles.capacityListHeader}>
      <div className={styles.capacityListHeaderTabs}>
        <Button
          type="button"
          primary={props.tabName === "Capacity"}
          onClick={() => props.setTabName("Capacity")}
        >
          Capacity
        </Button>

        {/* <Button
          type="button"
          primary={props.tabName === "CoveragePlans"}
          onClick={() => props.setTabName("CoveragePlans")}
        >
          Coverage Plans
        </Button> */}
      </div>

      <Button.Group compact size="small">
        {(() => {
          switch (props.tabName) {
            case "Capacity":
              return (
                <>
                  <Button
                    color="blue"
                    disabled={props.isEditing}
                    onClick={props.onAddNew}
                  >
                    New Cap
                  </Button>
                  <Button
                    color="blue"
                    disabled={props.isEditing}
                    loading={props.notesLoading}
                    onClick={() => props.onNotesChange("Left voicemail", "LVM")}
                  >
                    LVM
                  </Button>
                  <Button
                    color="blue"
                    disabled={props.isEditing}
                    loading={props.notesLoading}
                    onClick={props.onAddNewNoCapacity}
                  >
                    No Cap
                  </Button>
                </>
              );
            case "CoveragePlans":
              return (
                <Button color="blue" onClick={props.onAddNewPlanCapacity}>
                  New Plan Cap
                </Button>
              );
            default:
              return null;
          }
        })()}
        {props.mode === "many" && (
          <Button basic color="grey" onClick={props.onClose}>
            Close
          </Button>
        )}
      </Button.Group>
    </div>
  ) : null;
};
