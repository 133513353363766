import Loader from 'components/Loader';
import ListRow from './ListRow';

import styles from '../CarrierList.module.scss';

const ListBody = ({
  carriersLoading,
  carrierData,
  onRowClick,
  loadCarrierById,
  renderDropdownItems,
  onEditCarrier,
  user,
  selectedCarrier
}) => {
  if(carriersLoading) {
    return <Loader />;
  }

  if(!carrierData || carrierData.length === 0) {
    return <p className={styles.noData}>No data to display</p>;
  }

  return (
    carrierData.map(item => (
      <ListRow
        key={`lr_${item.id}`}
        item={item}
        onRowClick={onRowClick}
        loadCarrierById={loadCarrierById}
        renderDropdownItems={renderDropdownItems}
        onEditCarrier={onEditCarrier}
        user={user}
        active={selectedCarrier.id === item.id}
      />
    ))
  )
}

export default ListBody;