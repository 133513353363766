import { Table } from "semantic-ui-react";
import styled from "styled-components";

interface CapacityNoDataRowProps {
  onAddNew: () => void;
}

const CapacityNoDataRow = ({ onAddNew }: CapacityNoDataRowProps) => (
  <TableRow>
    <TableCell colSpan={12} textAlign="center">
      <Span>There are no capacities, please </Span>
      <Link onClick={onAddNew}>create a new one</Link>
    </TableCell>
  </TableRow>
);

export { CapacityNoDataRow };

const TableRow = styled(Table.Row)`
  background-color: #e8ebef;
`;

const TableCell = styled(Table.Cell)`
  text-align: center;
`;

const Span = styled.span`
  font-size: 2rem;
  opacity: 0.3;
`;

const Link = styled.span`
  color: #4183c4;
  font-size: 2rem;
  opacity: 0.7;
  cursor: pointer;
`;
