import styles from "./searchDropdown.module.scss";
import { Icon, Loader } from "semantic-ui-react";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import _debounce from "lodash/debounce";

interface SearchDropdownProps {
  onChange: (title: string) => void;
  searchFunction: unknown;
  value: string;
}

export const LocationSearchDropdown = ({
  searchFunction,
  onChange,
  value,
}: SearchDropdownProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [query, setQuery] = useState<string>(value);
  const [loading, setLoading] = useState<boolean>(false);
  const [noResults, setNoResult] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [results, setResults] = useState<{title: string}[]>([]);

  const resultsRef = useRef<HTMLDivElement>(null);

  const resultsAreaStyles: string = results.length
    ? `${styles.result_area} ${styles.visible}`
    : `${styles.result_area}`;

  const handleSearchResult = useCallback(async (value: string) => {
    if (!value) {
      setResults([]);
      onChange(value);
    }
    setInputValue(value);
    if (typeof searchFunction !== "function")
      throw new Error("SearchFunction must be a function");
    if (value.length < 2) return;

    if (!isSelected)
      try {
        setLoading(true);
        setResults([]);
        const res = await searchFunction(value.trim());
        const mappedRes = res.data.map((destination: string) => ({
          title: destination,
        }));
        setResults(mappedRes);

        if (!mappedRes.length) setNoResult(true);
      } finally {
        setLoading(false);
        setIsSelected(true);
      }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        resultsRef.current &&
        !resultsRef.current.contains(event.target as Node)
      ) {
        setResults([]);
        setNoResult(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    handleSearchResult(inputValue);
  }, [handleSearchResult, inputValue]);

  const handleSearchChange = _debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setInputValue(value);
  }, 4000);

  const onSelect = (item: { title: string }) => {
    setInputValue(item.title);
    setQuery(item.title);
    setIsSelected(true);
    onChange(item.title);
    setResults([]);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.input_area}>
        <input
          autoComplete="off"
          placeholder="Location"
          type="text"
          tabIndex={0}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setQuery(e.target.value);
            setIsSelected(false);
            handleSearchChange(e);
          }}
          value={query}
        />
        {loading ? (
          <div className={styles.search_loader} title="loading">
            <Loader className={styles.search_loader} />
          </div>
        ) : (
          <Icon
            name={"search"}
            className={styles.search_icon}
            link
            title="search-icon"
          />
        )}
      </div>
      <div ref={resultsRef}>
        <div className={resultsAreaStyles}>
          {results.map((item, index) => (
            <div
              key={`${index}-${item.title}`}
              className={styles.item}
              onClick={() => onSelect(item)}
            >
              {item.title}
            </div>
          ))}
        </div>
        {noResults ? (
          <div className={`${styles.result_area} ${styles.visible}`}>
            <div
              className={styles.item_no_result}
              onClick={() => setNoResult(false)}
            >
              No results found.
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
