import { CarrierCoveragePlanFormObject } from "../CarrierCoveragePlanForm";

export const getDefaultCarrierCoveragePlanFormObject =
  (): CarrierCoveragePlanFormObject => ({
    id: "",
    planId: null,
    laneId: null,
    rate: "",
    equipment: null,
    coverageAssigned: "",
    note: "",
  });
