import { CarrierCoveragePlanCapacityDto } from "../../../../models/dto/CarrierCoveragePlanCapacityDto";
import { CarrierCoveragePlanFormObject } from "../CarrierCoveragePlanForm";

export const mapFormToApiObject = (
  formObject: CarrierCoveragePlanFormObject
): CarrierCoveragePlanCapacityDto | null => {
  if (
    !formObject.planId ||
    !formObject.laneId ||
    !formObject.equipment ||
    Number(formObject.coverageAssigned) === 0
  ) {
    return null;
  }

  return {
    id: formObject.id ? Number(formObject.id) : null,
    planId: Number(formObject.planId),
    laneId: Number(formObject.laneId),
    rate: formObject.rate ? Number(formObject.rate) : null,
    equipment: formObject.equipment,
    coverageAssigned: Number(formObject.coverageAssigned),
    note: formObject.note.trim(),
    createdDate: null,
    lastModifiedUser: null,
  };
};
