import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as API from "api/usersAPI";
import { ProfilesCarrier } from "../../models/ProfilesCarrier";
import { AxiosError } from "axios";

export const fetchUserCarrierAssociation = createAsyncThunk<
  ProfilesCarrier[],
  {
    userId: number;
    gridState: {
      page: number;
      pageSize: number;
    };
  },
  { rejectValue: string }
>(
  "carrierAssociation/fetch",
  async ({ userId, gridState }, { rejectWithValue }) => {
    try {
      const response = await API.fetchUserCarrierAssociation({
        userId,
        gridState,
      });
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  }
);

export const deleteUserCarrierAssociation = createAsyncThunk<
  void,
  {
    userId: number;
    carrierId: number;
    gridState: {
      page: number;
      pageSize: number;
    };
  }
>(
  "carrierAssociation/delete",
  async ({ userId, carrierId, gridState }, thunkAPI) => {
    const data = await API.deleteUserCarrierAssociation({ userId, carrierId });
    if (data?.data) {
      await thunkAPI.dispatch(
        fetchUserCarrierAssociation({ userId: userId, gridState })
      );
    }
  }
);

export interface CarrierAssociationState {
  list: ProfilesCarrier[];
  loading: boolean;
  error: string | undefined;
}

export const initialState: CarrierAssociationState = {
  list: [],
  loading: false,
  error: undefined,
};

export const carrierAssociationSlice = createSlice({
  name: "carrierAssociation",
  initialState,
  reducers: {
    resetUserCarrierAssociation: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserCarrierAssociation.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserCarrierAssociation.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
        if (action.payload.length === 0) {
          state.error = "No UserCarrierAssociation were found";
        }
      })
      .addCase(fetchUserCarrierAssociation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(deleteUserCarrierAssociation.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUserCarrierAssociation.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteUserCarrierAssociation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const actions = {
  ...carrierAssociationSlice.actions,
  fetchUserCarrierAssociation,
  deleteUserCarrierAssociation,
};

export const reducer = carrierAssociationSlice.reducer;
