import { useEffect, useState } from "react";
import cx from "classnames";
import { Confirm } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import { useSignalR } from "shared/hooks/useSignalR";
import CapacityList from "./CapacityList/index";
import { actions } from "redux/capacityMatching/slice";
import {
  PREFERRED_LANE,
  ReceiveCapacityNotificationUpdate_Message,
  NO_CAPACITY,
} from "shared/constants";
import { MatchesList } from "./MatchesList";
import * as storeActions from "redux/store/actions";

import styles from "./CapacityMatchingSegment.module.scss";

/**
 * CapacityMatchingSegment standalone component which provides capacity matсhing functionality.
 * @param {strig} mode - component allows to work with different Carriers [many]
 *  or with a [single] carrier without dropdown and close button.
 */
const CapacityMatchingSegment = ({
  carrierId,
  carrierName = "",
  onClose,
  onCarrierNoteUpdate,
  mode = "many",
}) => {
  const dispatch = useDispatch();
  const [capacityIdToRemove, setCapacityIdToRemove] = useState(null);
  const [selectedCapacityId, setSelectedCapacityId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const capacityMatchingState = useSelector((state) => state.capacityMatching);

  // Destructure state properties with null check
  const { carriers, capacityInfo, matches, activityNotes } =
    capacityMatchingState || {};

  const { data: messagesData } = useSignalR(`${process.env.REACT_APP_API_URL}/api/carriershub`, [
    ReceiveCapacityNotificationUpdate_Message,
  ]);
  const { user } = useSelector((state) => state.oidc);

  useEffect(() => {
    dispatch(actions.getEquipmentOptions());
  }, [dispatch]);

  useEffect(() => {
    if (carrierId) {
      dispatch(actions.getCapacityInfo({ carrierId }));
      dispatch(
        actions.setSelectedCarrier({ id: carrierId, name: carrierName }),
      );
      setSelectedCapacityId(null);
      setIsEditing(false);
    }
  }, [carrierId, carrierName, dispatch]);

  useEffect(() => {
    if (
      messagesData &&
      messagesData[ReceiveCapacityNotificationUpdate_Message]?.length > 0
    ) {
      dispatch(
        actions.receiveNotifications(
          messagesData[ReceiveCapacityNotificationUpdate_Message],
        ),
      );
    }
  }, [messagesData, dispatch]);

  const reFatchCarierList = () => {
    const { firstName, lastName } = user.profile;
    // to fetch carrers on new creation

    const carrierFilter = JSON.parse(
      localStorage.getItem(`${firstName}_${lastName}_profiles-filter`),
    );
    const { popup, pagination, sorting, additional } = carrierFilter;
    dispatch(
      storeActions.fetchCarriers({
        ...popup,
        ...pagination,
        ...sorting,
        ...additional,
      }),
    );
  };

  const handleRowUpdate = (row) => {
    const updRow = {
      ...row,
      rate: row.rate || 0,
      availabilityDate:
        row.type === PREFERRED_LANE || row.type === NO_CAPACITY
          ? null
          : row.availabilityDate,
      expirationDate:
        row.type === PREFERRED_LANE || row.type === NO_CAPACITY
          ? null
          : row.expirationDate,
    };
    if (updRow.id === 0) {
      dispatch(
        actions.createCapacityInfo({
          carrierId: carriers.selectedCarrier.id,
          capacityInfoItem: updRow,
        }),
      ).then((res) => {
        if (row.type === NO_CAPACITY) {
          handleNotesChange(
            `${carriers.selectedCarrier.name} Does not have capacity.`,
            "NO_CAP",
          );
        } else {
          let capacityId = res.payload?.id;
          if (capacityId) {
            setSelectedCapacityId(capacityId);
          }
        }
      });
    } else {
      setSelectedCapacityId(updRow.id);
      dispatch(
        actions.updateCapacityInfo({
          carrierId: carriers.selectedCarrier.id,
          capacityInfoItem: updRow,
        }),
      );
    }
  };

  const handleCapacitySelect = (capacityId) => {
    setSelectedCapacityId(capacityId);
    dispatch(
      actions.getCapacityMatchingLog({
        carrierId: carriers.selectedCarrier.id,
        capacityInfoItemId: capacityId,
        requestBody: {
          page: 1,
          pageSize: matches.pageSize,
        },
      }),
    );
  };

  const handleRemoveCapacityItem = (capacityItemId) => {
    setCapacityIdToRemove(capacityItemId);
  };

  const confirmRemoveCapacityItem = () => {
    setCapacityIdToRemove(null);
    dispatch(
      actions.deleteCapacityItem({
        carrierId: carriers.selectedCarrier.id,
        capacityItemId: capacityIdToRemove,
      }),
    ).then(() => reFatchCarierList());
    if (capacityIdToRemove === selectedCapacityId) {
      setSelectedCapacityId(null);
    }
  };

  const handleCapacityMatchesAutomateRefresh = (selectedCapacityId) => {
    dispatch(
      actions.triggerCapacityMatchingAutomate({
        carrierId: carriers.selectedCarrier.id,
        capacityInfoItemId: selectedCapacityId,
      }),
    );
  };

  const handleCapacityMatchesRefresh = () => {
    dispatch(
      actions.triggerCapacityMatching({
        carrierId: carriers.selectedCarrier.id,
        capacityInfoItemId: selectedCapacityId,
      }),
    );
  };

  const handleCapacityRowCreationCancel = () => {
    dispatch(actions.cancelCapacityItemCreation());
  };

  const handleCapacityInfoPageSizeChange = (pageSize) => {
    dispatch(
      actions.setCapacityInfoPageSize({
        pageSize,
        carrierId: carriers.selectedCarrier.id,
      }),
    );
  };

  const handleCapacityInfoPageChange = (page) => {
    dispatch(
      actions.setCapacityInfoPage({
        page,
        pageSize: capacityInfo.pageSize,
        carrierId: carriers.selectedCarrier.id,
      }),
    );
  };

  const handleMatchesPageSizeChange = (pageSize) => {
    dispatch(
      actions.setMatchesPageSize({
        pageSize,
        carrierId: carriers.selectedCarrier.id,
        capacityInfoItemId: selectedCapacityId,
      }),
    );
  };

  const handleMatchesPageChange = (page) => {
    dispatch(
      actions.setMatchesPage({
        page,
        pageSize: matches.pageSize,
        carrierId: carriers.selectedCarrier.id,
        capacityInfoItemId: selectedCapacityId,
      }),
    );
  };

  const handleNotesChange = (noteMessage, noteType) => {
    dispatch(
      actions.updateActivityNotes({ noteType, carrierId, noteMessage }),
    ).then(() => {
      if (typeof onCarrierNoteUpdate === "function") {
        onCarrierNoteUpdate(carrierId);
      }
    });
  };

  const handleCapacityExpire = (data) => {
    dispatch(
      actions.deleteCapacityItem({ carrierId, capacityItemId: data.id }),
    );
  };

  const handleAddNewCapacityItem = () => {
    setIsEditing(true);
    dispatch(actions.addNewCapacityInfo());
  };
  const handleAddNoCapacityItem = () => {
    setIsEditing(true);
    dispatch(actions.addNoCapacityInfo());
  };

  const handleMatchesClose = () => {
    setSelectedCapacityId(null);
  };

  return (
    <div
      className={cx(
        styles.capacityMatchingContainer,
        selectedCapacityId
          ? styles.capacitySelected
          : styles.capacityNotSelected,
      )}
    >
      <CapacityList
        data={capacityInfo?.list}
        loading={capacityInfo?.loading}
        isEditing={isEditing}
        selectedItemId={selectedCapacityId}
        loadingRowId={capacityInfo?.capacityLoadingId}
        mode={mode}
        notesLoading={activityNotes?.loading}
        carrier={carriers?.selectedCarrier}
        tableActions={{
          onAddNew: handleAddNewCapacityItem,
          onAddNoCap: handleAddNoCapacityItem,
          onRowUpdate: handleRowUpdate,
          onRowCreationCancel: handleCapacityRowCreationCancel,
          onRowDelete: handleRemoveCapacityItem,
          onCapacityMatchesSelect: handleCapacitySelect,
          onCapacityExpire: handleCapacityExpire,
          onStartEdit: () => setIsEditing(true),
          onFinishEdit: () => setIsEditing(false),
          onNotesChange: handleNotesChange,
          onClose: onClose,
        }}
        pagination={{
          page: capacityInfo?.page,
          pageSize: capacityInfo?.pageSize,
          total: capacityInfo?.total,
          onPageChange: handleCapacityInfoPageChange,
          onPageSizeChange: handleCapacityInfoPageSizeChange,
        }}
      />
      {selectedCapacityId && (
        <MatchesList
          data={matches.list}
          loading={matches.loading}
          total={matches.total}
          onPageSizeChange={handleMatchesPageSizeChange}
          onPageChange={handleMatchesPageChange}
          page={matches.page}
          selectedCapacityId={selectedCapacityId}
          pageSize={matches.pageSize}
          onCapacityInfoRefresh={handleCapacityMatchesRefresh}
          onCapacityInfoAutoRefresh={(ID) =>
            handleCapacityMatchesAutomateRefresh(ID)
          }
          onClose={handleMatchesClose}
        />
      )}
      <Confirm
        content="Do you want to delete the capacity?"
        open={!!capacityIdToRemove}
        onCancel={() => setCapacityIdToRemove(null)}
        onConfirm={confirmRemoveCapacityItem}
      />
    </div>
  );
};

export default CapacityMatchingSegment;
